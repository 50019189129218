import React from "react";
import { CounselDetails } from "../../assets/Images";
const ConsulDetails = ()=>{
      return (
            <div className='timeLineConsulMain'>
                  <div className="counselDetails">
                  <h3>Consultation details</h3>
                  <div className="consulDetailsContent mt-[20px]">
                        <div className="detailIconCons">
                              <CounselDetails /> 
                        </div>
                        <div className="detailsAllContent">
                              <div className="detailOnSIngleLINE">
                             <div className="singleContentDetails">
                              <p className="detailQ">Type:</p>
                              <p className="detailA">Video Appointment</p>
                             </div>
                             <div className="singleContentDetails">
                              <p className="detailQ">Booked:</p>
                              <p className="detailA">9/2/2000</p>
                             </div>
                             <div className="singleContentDetails">
                              <p className="detailQ">Location:</p>
                              <p className="detailA">Japan</p>
                             </div>
                             </div>
                              <div className="detailOnSIngleLINE mt-[10px]">
                             
                           
                             <div className="singleContentDetails">
                              <p className="detailQ">Reachable address:</p>
                              <p className="detailA">112 Ohio street, Park Lance, Right sector, MIL 345.</p>
                             </div>
                             </div>
                              <div className="detailOnSIngleLINE mt-[10px]">
                             
                           
                             <div className="singleContentDetails">
                              <p className="detailQ">Disease:</p>
                              <p className="detailA">Sexually Transmitted Disease</p>
                             </div>
                             
                             </div>
                              <div className="detailOnSIngleLINE mt-[10px]">
                             
                           
                             <div className="singleContentDetails">
                             <h5>Disease details</h5>
                             </div>
                             
                             </div>
                        </div>
                  </div>
                  </div>
            </div>
      );
}
export default ConsulDetails