import React, { useEffect } from 'react'
import ConsultationsContent from '../../components/consultation/ConsultationsContent'
import ConsultationSideBar from '../../components/consultation/ConsultationSideBar'
import './consultation.css'
import { ApiCall } from '../../Services/apis'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'


const Consultation = () => {
  const {id} = useParams()
  const token = useSelector((state) => state.auth.userToken);

  const gettingBookingDetails =async ()=>{
    
    try{
      console.log("id on this page",id);
      const res  = await ApiCall({
        route:`api/booking/get_booking_detail/${id}`,
        token:token,
        verb:'get'
      })
      if(res?.status == 200){
        console.log(res?.response)
      }
    }
    catch(error) {

    }

  }
  useEffect(()=>{
    gettingBookingDetails()
  },[])
  return (
    <div className='mainConsultationContainer'>
    <h1>My Consultation</h1>

    <div className='consulContent'>
      <ConsultationSideBar />
      <ConsultationsContent />

    </div>
      

    </div>
  )
}

export default Consultation