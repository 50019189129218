import React, { useState } from "react";
import { Button, Drawer, Space } from "antd";
import "./DrawerApp.css";
import { Link } from "react-router-dom";
import Details from "../Details/Details";
import FileIcon from "../../assets/Images/FileIcon";
import PrimaryButton from "../UI/PrimaryButton";

const DrawerApp = ({ bookingDetail, getBookingDetails }) => {
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const showDefaultDrawer = () => {
    setSize("default");
    setOpen(true);
  };

  const showLargeDrawer = () => {
    setSize("large");
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="Main_Drawer">
        <Space>
          <PrimaryButton
            onClick={() => {
              getBookingDetails();
              showLargeDrawer();
            }}
            className="details-drawer-consultation-in-video-call"
            icon={<FileIcon />}
          >
            Consultation Details
          </PrimaryButton>
        </Space>
        <Drawer
          // title={`Booking Detail `}
          placement="right"
          size={size}
          onClose={onClose}
          open={open}
        >
          <Details Details={bookingDetail} />
        </Drawer>
      </div>
    </>
  );
};

export default DrawerApp;
