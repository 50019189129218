import React, { useState, useEffect } from "react";
import {
  GreyDot,
  Next,
  Previous,
  RedDot,
  ReviewGrey,
  ReviewIcon,
  ReviewPicOne,
  ReviewPicTwo,
  ReviewRed,
  Slider,
} from "../../../../assets/Images";

import "./Reviews.css";
const ReviewsData = [
  {
    id: "10",
    name: "RK",
    type: "Influenza (Flu)",
    review:
      "Definitely the very best experience we had anywhere! From front desk manager to nurse to doctor 💕👍👏",
    picture: <ReviewPicOne />,
  },
  {
    id: "2",
    name: "Carina Y.",
    type: "Sinus Infection",
    review:
      "They smile and make you feel at home. They saw me immediately upon arrival. A lot of Doctors should learn from this Clinic.",
    picture: <ReviewPicTwo />,
  },
  {
    id: "3",
    name: "Paul B.",
    type: "Strep Throat",
    review:
      "Ok, so yes, you do pay out of pocket for all this, but it is some of the best money I have spent. Can’t recommend enough.",
    picture: <ReviewPicOne />,
  },
  {
    id: "4",
    name: "Sable S.",
    type: "Asthma",
    review:
      "I'm a physician from the US. Highly recommend. Great staff as well.",
    picture: <ReviewPicTwo />,
  },
  {
    id: "6",
    name: "Mark G.",
    type: "Vaginal Yeast Infection (Candidiasis)",
    review:
      "In terms of service, this clinic is quite The Hilton. It is not a cheap service but when I fall ill in a foreign country, I want to ensure effective treatment and this is the place to go.",
    picture: <ReviewPicOne />,
  },
  {
    id: "7",
    name: "Amanda A.",
    type: "Urinary Tract Infection (UTI)",
    review: "I loved their small practice and would highly recommend them.",
    picture: <ReviewPicTwo />,
  },
  {
    id: "8",
    name: "Kevin C.",
    type: "Strep Throat",
    review: "Highly recommend this clinic to travelers!",
    picture: <ReviewPicOne />,
  },

  {
    id: "9",
    name: "Chris M.",
    type: "Influenza (Flu)",
    review:
      "I am very happy with the level of service. They go beyond what is expected and were very compassionate.",
    picture: <ReviewPicOne />,
  },
  {
    id: "0",
    name: "Dave R.",
    type: "Orthopedic",
    review: "I can't say enough about the quality of the service",
    picture: <ReviewPicOne />,
  },
];

const Reviews = () => {
  const [visibleReviews, setVisibleReviews] = useState([]);
  const [count, setCount] = useState(0);
  const [active, setActive] = useState(false);
  useEffect(() => {
    getVisibleReviews();
  }, [count]);
  const getVisibleReviews = () => {
    setVisibleReviews(ReviewsData.slice(count, count + 1));
  };
  // move right
  const moveRight = () => {
    // ReviewsData[count + 1] && setCount(count + 1);
    if (count < ReviewsData.length - 1) {
      setCount(count + 1);
    } else {
      setCount(0);
    }
  };
  // move left
  const moveLeft = () => {
    // count !== 0 && setCount(count - 1);
    if (count > 0) {
      setCount(count - 1);
    } else {
      setCount(ReviewsData.length - 1);
    }
  };
  const changeStatus = () => {
    setActive(!active);
  };
  return (
    // <section className="md:mt-[60px]  mt-[40px]">
    <section>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
        // className=""
      >
        <div className="row">
          <center>
            <h1 className="works-heading responsiveWorkHeading">
              <span className="span-color">Our</span> Patients say
            </h1>
          </center>
        </div>
        <div className="row  md:w-[auto] row-for-tabs w-[90%]">
          {visibleReviews.map((data) => {
            return (
              <>
                <div
                  className="reviewBg md:block hidden "
                  data-aos="fade-right"
                  key={data.id}
                >
                  <div className="review-box md:ml-[50px] flex justify-center align-center  ">
                    <div className="w-[60%]">
                      <div className="h-[135px]">
                        <p className="review-text ">{data.review} </p>
                      </div>
                      <div class="flex mt-[40px] ">
                        <ReviewIcon />
                        <div className="review-name pl-[10px] md:w-[200px] ">
                          <h2>{data.name}</h2>
                          {/* <p>{data.type}</p> */}
                        </div>
                        <div className="flex md:ml-[50px] e">
                          <div
                            className="pointer cursor-pointer"
                            onClick={() => {
                              moveLeft();
                              changeStatus();
                            }}
                          >
                            <Previous />
                          </div>
                          <div
                            className="pointer ml-[10px] cursor-pointer"
                            onClick={() => {
                              moveRight();
                              changeStatus();
                            }}
                          >
                            <Next />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-[40%] review-imageBg  flex">
                      <div className="review-image">{data.picture}</div>
                      {/* <div className=" slider pl-[80px]">
                        {active === true ? (
                          <RedDot className="mt-[5px]" />
                        ) : (
                          <GreyDot />
                        )}
                        {active === true ? (
                          <GreyDot className="mt-[5px]" />
                        ) : (
                          <RedDot className="mt-[5px]" />
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
              </>
            );
          })}

          {/* mobile design */}

          {visibleReviews.map((data) => {
            return (
              <>
                <div className="review-boxM md:hidden block">
                  <div className="w-[100%]">
                    <p className="review-text  h-[135px]">{data.review}</p>
                    <div class="flex mt-[40px]">
                      <ReviewIcon />
                      <div className="review-name pl-[10px] w-[200px]">
                        <h2>{data.name}</h2>
                        <p>{data.type}</p>
                      </div>
                      <div className="flex  ml-[30px] ">
                        <div className="pointer" onClick={moveLeft}>
                          <Previous />
                        </div>
                        <div className="pointer ml-[10px]" onClick={moveRight}>
                          <Next />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Reviews;
