import React from 'react'

const DocDetails = () => {
  return (
      <div className='timeLineConsulMain'>
            <div className='docDetailInConsul'>
                  <h3>Doctor Details</h3>
                  <div className='docDetailsContainer'>
                        <div className='docImageFirst'>
                              <img src='/images/profile-img.png' alt='profile' />
                        </div>
                        <div className='docDetailsContent'>
                              <div className="singleDocConsul">
                                    <p className='docDetailsQ'>Full Name : </p>
                                    <p className='docDetailsA'>John Doe </p>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
  )
}

export default DocDetails