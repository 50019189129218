import "./DetailsPage.css";
import {
  MedIcon,
  Thermameter,
  History,
  Umbrella,
} from "../../assets/Images/index";

const MedHistory = ({ bookingDetail }) => {
  console.log("med", bookingDetail);
  return (
    <>
      {bookingDetail?.medical_history?.booking_reason ? (
        <div className="w-[100%] mx-auto">
          <div className="med-middle-sec">
            <div className="med-headings">
              <h3>What brings you here today?</h3>
              {bookingDetail.medical_history?.booking_reason == "" ? (
                <p>Not provided</p>
              ) : (
                <p className="p-[10px] w-[400px] p-[12px]">
                  {bookingDetail?.medical_history?.booking_reason}{" "}
                </p>
              )}
            </div>
            <div className="med-headings">
              <h3>Existing Allergies</h3>
              {bookingDetail?.medical_history?.allergy.length == 0 ? (
                <p>No Allergies </p>
              ) : (
                <ul className="flex pl-[0px] flex-wrap">
                  {bookingDetail?.medical_history?.allergy.map((data) => (
                    <li className="m-[4px]">{data + ","}</li>
                  ))}
                  {/* flex pl-[0px] flex-wrap */}
                </ul>
              )}
            </div>
          </div>
          <div className="med-middle-sec">
            <div className="med-headings">
              <h3>Existing Medications</h3>
              {bookingDetail?.medical_history?.medication == 0 ? (
                <p>No Medications Provided</p>
              ) : (
                <div className="medications-list">
                  {bookingDetail?.medical_history?.medication.map((data) => (
                    <p className="m-[10px]">{data + ","}</p>
                  ))}
                </div>
              )}
            </div>
          </div>
          {bookingDetail?.personal_info?.gender === "male" ? null : (
            <div className="med-middle-sec">
              <div className="med-headings">
                <h3>Pregnancy status</h3>
                <ul>
                  <li className="pb-[10px]">
                    {bookingDetail?.medical_history?.pregnancy_status}
                  </li>
                </ul>
              </div>
            </div>
          )}
          <div className="med-middle-sec">
            <div className="med-headings">
              <h3>Past Medical History</h3>
              {bookingDetail?.medical_history?.past_history == null ? (
                <p>No Previous Medical Record</p>
              ) : (
                <p className="p-[10px] w-[400px] pr-[12px]">
                  {bookingDetail?.medical_history?.past_history}{" "}
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="">
            <div className="flex justify-center items-center align-center w-[100%]">
              <p className="text-slate-400	text-[20px] text-center font-semibold py-[5%]">
                You have not provided any medical history!
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MedHistory;
