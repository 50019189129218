import React, { useState } from "react";
import Swal from "sweetalert2";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../../Services/apis";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Logo from "../../../assets/Images/AmericanClinicLoginLogo.svg";
import "./Review.css";
import { setLoader } from "../../../Redux/Actions/GeneralActions";

export default function Review({}) {
  const token = useSelector((state) => state.auth.userToken);
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const firstExample = {
    size: 35,
    count: 5,
    color: "black",
    activeColor: "#002fa7",
    value: 0,
    a11y: true,
    isHalf: false,
    emptyIcon: <i className="far fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
    onChange: (newValue) => {
      setRating(newValue);
    },
  };

  // const data = {
  //   comment,
  //   rating,
  // };

  const handleSubmit = async (e) => {
    dispatch(setLoader(true));
    e.preventDefault();

    if (rating === 0) {
      dispatch(setLoader(false));
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Rating is required",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }

    const data = {
      comment: comment || "-",
      rating,
    };

    try {
      const res = await ApiCall({
        route: `review/add_review/${id}`,
        verb: "post",
        token: token,
        params: data,
      });
      //   userBook();
      console.log("review", res);
      if (res?.status == 200) {
        dispatch(setLoader(false));

        Swal.fire({
          position: "success",
          icon: "success",
          title: "Review sent ",
          showConfirmButton: false,
          timer: 2000,
        });

        navigate(`/patient/details/${id}`);
      } else {
        dispatch(setLoader(false));

        Swal.fire({
          position: "center",
          icon: "error",
          title: "We couldn’t record your review!",
          text: "Stars and feedback cannot be empty.",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (e) {
      dispatch(setLoader(false));

      Swal.fire("Review Can't submit right now", "", "error");
    }
  };

  const handleSkip = () => {
    navigate(`/patient/details/${id}`);
  };

  return (
    <section className="h-[100vh] ">
      <div className="h-[15vh]  bg-[#edf2ff] md:px-[60px] px-[30px] flex md:flex-row flex-col md:justify-between w-full items-center md:pt-[0px] pt-[20px]">
        <Link to="/">
          <img src={Logo}></img>
        </Link>
        <h3 className="review-heading md:pt-[0px] pt-[10px]">
          {/* Consultation Completed */}
        </h3>
      </div>
      {/* <div className="justify-center  items-center flex overflow-x-hidden h-[85vh]  fixed inset-0 z-50 outline-none focus:outline-none"> */}
      <div className="relative container w-full mt-[5%] mx-auto  ">
        {/*content*/}
        <div className="border-0 py-[40px]  rounded-lg shadow-lg relative flex flex-col md:w-[50%] w-[90%] mx-auto bg-white outline-none focus:outline-none">
          {/*header*/}

          <div className="rounded-t mb-[20px]">
            <h3 className="text-2xl text-gray-700 text-center font-semibold">
              Rate your experience.
            </h3>
          </div>
          {/*body*/}
          <div className="relative  w-[90%] mx-auto  flex-auto ">
            <div className="relative md:w-[70%] mx-auto flex-auto mb-[20px]">
              <h3 className="text-sm text-gray-700  font-bold">Your rating</h3>
              <ReactStars {...firstExample} />
            </div>

            <div className="relative md:w-[70%] mx-auto flex-auto">
              <div class="mb-3 w-full">
                <label
                  for="exampleFormControlTextarea1"
                  className="form-label text-sm text-gray-700  font-bold"
                >
                  Your experience
                </label>
                <textarea
                  className="
                                form-control
                                block
                                w-full
                                px-3
                                py-1.5
                                text-sm
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                            "
                  id="comment"
                  onChange={(event) => setComment(event.target.value)}
                  rows="3"
                  placeholder="Enter your review here"
                ></textarea>
              </div>
            </div>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-center py-2     ">
            {" "}
            <button
              className="bg-[#002fa7] text-white font-bold uppercase text-sm px-7 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit Review
            </button>
            <button
              className="bg-gray-500 text-white font-bold uppercase text-sm px-7 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ml-2 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleSkip}
            >
              Skip Review
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </section>
  );
}
