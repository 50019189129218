import React, { useState, useEffect } from "react";
import "./Login.css";
import Logo from "../../assets/Images/AmericanClinicLoginLogo.svg";
import { Input } from "antd";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import validator from "validator";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  signUpUserRequest,
  startSignUpAsGuest,
} from "../../Redux/Actions/AuthActions";
import { setLoader } from "../../Redux/Actions/GeneralActions";
import PrimaryButton from "../../components/UI/PrimaryButton";

const Signupp = () => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    dob: "",
  });
  const [value, setValue] = useState(null);
  const navigate = useNavigate();

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = handleValidation();
    console.log(errors, "erros");
    if (Object.keys(errors).length !== 0) {
      return;
    }

    dispatch(
      signUpUserRequest({ data: { ...data, dob: value?.dateString }, navigate })
    );
    dispatch(setLoader(true));
    // navigate("/login");
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  }, []);

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });

  const onChange = (date, dateString) => {
    setValue({ dateString });
  };

  const handleValidation = () => {
    const validationErrors = {};

    // Check if any field is empty and save the error
    if (data.first_name.trim() === "" || !data.first_name) {
      validationErrors.first_name = "First name is required";
    }
    if (data.last_name.trim() === "" || !data.last_name) {
      validationErrors.last_name = "Last name is required";
    }
    if (
      data.email.trim() === "" ||
      !validator.isEmail(data.email) ||
      !data.email
    ) {
      validationErrors.email = "Email is required";
    }
    if (data.password.trim() === "" || !data.password) {
      validationErrors.password = "Password is required";
    }
    if (value?.dateString.trim() === "" || !value?.dateString) {
      validationErrors.dob = "Date of birth is required";
    }

    const dob = new Date(value?.dateString);
    const currentDate = new Date();
    const userAge =
      currentDate.getFullYear() -
      dob.getFullYear() -
      (currentDate <
      new Date(currentDate.getFullYear(), dob.getMonth(), dob.getDate())
        ? 1
        : 0);

    if (value?.dateString?.trim() && userAge < 15) {
      validationErrors.dob = "Patient must be at least 15 years old.";
    }

    // Update the errors state object
    setErrors(validationErrors);
    return validationErrors;
  };

  const continueAsGuestHandler = () => {
    dispatch(startSignUpAsGuest({ status: true }));
  };

  return (
    <section className="h-[100vh] ">
      <div
        className="h-[15vh] back-color"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Link to="/">
          <img src={Logo}></img>
        </Link>
      </div>
      <div
        className=" w-[100%] "
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <center>
          <h2 className="login-heading mt-[50px]">Sign up</h2>
        </center>
        <form
          onChange={({ target: { value, id } }) => {
            setData({
              ...data,
              ...(id && { [id]: value }),
            });
          }}
          autoComplete="off"
        >
          <div className="w-[363px] ">
            <Input
              required
              placeholder="Given name"
              id="first_name"
              className="inputt"
              autoComplete="off"
              style={{
                marginTop: "10px",
                width: "100%",
                height: "42px",
                borderRadius: "10px",
              }}
            />
            <span
              style={{
                fontWeight: "light",
                fontSize: "12px",
                color: "red",
              }}
            >
              {errors.first_name}
            </span>
            <Input
              placeholder="Surname"
              id="last_name"
              className="inputt"
              required
              autoComplete="off"
              style={{
                marginTop: "10px",
                width: "100%",
                height: "42px",
                borderRadius: "10px",
              }}
            />
            <span
              style={{
                fontWeight: "light",
                fontSize: "12px",
                color: "red",
              }}
            >
              {errors.last_name}
            </span>
            <DatePicker
              onChange={onChange}
              disabledDate={disabledDate}
              disabledTime={disabledDateTime}
              className="inputt"
              id="dob"
              autoComplete="off"
              style={{
                marginTop: "8px",
                width: "100%",
                height: "42px",
                borderRadius: "10px",
              }}
              inputReadOnly={true}
              placeholder="Date of Birth"
            />{" "}
            <span
              style={{
                fontWeight: "light",
                fontSize: "12px",
                color: "red",
              }}
            >
              {errors.dob}
            </span>
            <Input
              placeholder="Email Address"
              id="email"
              className="inputt"
              required
              autoComplete="off"
              // onChange={(e) => emailvalidation(e.target.value)}
              style={{
                marginTop: "10px",
                width: "100%",
                height: "42px",
                borderRadius: "10px",
              }}
            />
            <span
              style={{
                fontWeight: "light",
                fontSize: "12px",
                color: "red",
              }}
            >
              {errors.email}
            </span>
            <Input.Password
              placeholder="Password"
              id="password"
              className="inputt"
              type="password"
              required
              autoComplete="off"
              style={{
                marginTop: "10px",
                width: "100%",
                height: "42px",
                borderRadius: "10px",
              }}
            />
            <span
              style={{
                fontWeight: "light",
                fontSize: "12px",
                color: "red",
              }}
            >
              {errors.password}
            </span>
            <div className="w-[100%] mt-[10px]">
              <div className="flex justify-between">
                <Link
                  to="/prescription/urgent"
                  onClick={continueAsGuestHandler}
                >
                  <button className="guest-btn" type="button">
                    Continue as guest
                  </button>
                </Link>
                {/* <Link to="/disclaimer"> */}
                <PrimaryButton className="signup-btn " onClick={handleSubmit}>
                  Sign Up
                </PrimaryButton>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </form>

        <div className="mt-[40px] mb-[20px]">
          <center>
            <p className="signup">
              Already have an account?
              <span className="text-[#002FA7] ml-[5px] ">
                <Link to="/login">Login</Link>
              </span>
            </p>
          </center>
        </div>
      </div>
    </section>
  );
};

export default Signupp;
