import React from "react";
import "./Loader.css";

const Loader = ({ height = "100vh", width }) => {
  return (
    <div
      style={{ height, width, zIndex: "10000000" }}
      className="spinnerContainer  fixed inset-0 z-50 w-full  h-[100vh] "
    >
      <div className="loader-box">
        <div className="loadingSpinner "></div>
      </div>
    </div>
  );
};

export default Loader;
