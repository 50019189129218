import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader/Loader";
import AuthRoutes from "./AuthRoutes";
import HomeRoutes from "./HomeRoutes";
import { useEffect } from "react";
import socket from "../pages/messaging/socketConnection";
import { setOnlineUsers } from "../Redux/Actions/OnlineUsersActions";
import { message } from "antd";
import notificationSound from "../assets/notification.wav";

const MainRoutes = () => {
  const user = useSelector((state) => state.auth.userData);
  const loader = useSelector((state) => state.general.loader);
  const dispatch = useDispatch();

  console.log(user);

  const handleSetOnline = (userId) => {
    socket.emit("setOnline", { userId });
  };

  const handleSetOffline = (userId) => {
    socket.emit("setOffline", { userId });
  };

  useEffect(() => {
    if (user && user?.user_id) {
      handleSetOnline(user?.user_id);
      socket.emit("join-notification", { userId: user?.user_id });

      window.addEventListener("beforeunload", () => {
        handleSetOffline(user?.user_id);
      });

      window.addEventListener("unload", () => {
        handleSetOffline(user?.user_id);
      });

      socket.on("updateOnlineUsers", (data) => {
        console.log(data, "online users data");
        dispatch(setOnlineUsers({ onlineUsers: data }));
      });

      return () => {
        handleSetOffline(user?.user_id);
        window.removeEventListener("beforeunload", () => {
          handleSetOffline(user?.user_id);
        });
        window.removeEventListener("unload", () => {
          handleSetOffline(user?.user_id);
        });
      };
    }
  }, [user]);

  useEffect(() => {
    socket.on("notifications", (notice) => {
      if (user?.user_id !== notice?.senderId) {
        if (notice.role === "patient") {
          if (notice?.usedFor !== "just_update") {
            const audio = new Audio(notificationSound);
            audio.muted = false;
            audio?.play();
            console.log("Showing from socket");
            message.open({
              content:
                notice?.notifications?.[notice?.notifications?.length - 1]
                  ?.notice,
              type: "info",
              key: "1234",
            });
          }
        }
      }
    });
    return () => {
      socket.off("notifications");
    };
  }, [socket]);

  return (
    <div>
      {loader ? <Loader /> : null}
      {user ? <HomeRoutes /> : <AuthRoutes />}
    </div>
  );
};

export default MainRoutes;
