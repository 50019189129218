import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDyN5895Fqyih8C6TT_SEXQ-l_ZQ3tKpfs",
  authDomain: "ohanadoc-aa4ff.firebaseapp.com",
  projectId: "ohanadoc-aa4ff",
  storageBucket: "ohanadoc-aa4ff.appspot.com",
  messagingSenderId: "569400077496",
  appId: "1:569400077496:web:0f32a9eaef5fb526f2984d",
  measurementId: "G-GXQ38PTERB",
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);
