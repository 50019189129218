import React from 'react'

const NotesPage = ({bookingDetail}) => {
  return (
    <div className="notes-card">
    <div className='flex justify-center items-center align-center w-[100%] h-[100%]'>
  <p className='text-slate-400	text-[30px] font-semibold'>Coming Soon ...</p>
  </div>
   
   </div>
  )
}

export default NotesPage