import React from "react";
import UrgentAppointment from "../../../components/Patient/UrgentAppointment/UrgentAppointment";

const UrgentCareMain = () => {
  return (
    <div>
      <UrgentAppointment />
    </div>
  );
};

export default UrgentCareMain;
