import { ACTIONS } from "../Action-types";

const initialState = {
  loader: false,

};

const GeneralReducers = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_LOADER:
      return {
        ...state,
        loader: action.data,
      };
       

    default:
      return state;
  }
};

export default GeneralReducers;
