import React, { useEffect, useState } from "react";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../Services/apis";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { setLoader } from "../../Redux/Actions/GeneralActions";
import moment from "moment";

const PaymentPay = ({ bookingID, data }) => {
  console.log("DAAAAAAAAAAAA", data);
  const [bookingData, setBookingData] = useState();
  const scheduleId = bookingData?.booking.schedule.timeslot_id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.userToken);
  const categioryID = useSelector((state) => state.auth.categioryId);
  const user = useSelector((state) => state.auth.userData);
  const tokenRecieved = async (tokenRecieved) => {
    const amount = 200;
    try {
      const res = await ApiCall({
        route: `app_api/square_payment`,
        verb: "post",
        params: {
          source_id: tokenRecieved?.token,
          amount: amount,
          booking_id: bookingID,
        },
      });
      if (res?.status == "400") {
        Swal.fire("Payment Failed", "", "error");
        const res = await ApiCall({
          route: `booking/delete_booking/${bookingID}`,
          verb: "delete",
          token: token,
        });
        dispatch(setLoader(false));
      } else {
        if (res?.status == "200") {
          const res = await ApiCall({
            route: `booking/update_video_booking/${bookingID}`,
            verb: "put",
            token: token,
            params: {
              timeslot_id: data?.schedule?.timeslot_id || data?.schedule?._id,
              //  data?.schedule?.schedule?._id
              //  data?.schedule?.timeslot_id
              // data?.schedule?._id,
              time: {
                start: moment(data?.schedule?.start_time)?.format("hh:mm"),
                end: moment(data?.schedule?.end_time)?.format("hh:mm"),
              },
            },
          });
          if (res?.status == "200") {
            navigate("/video-booking");
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Booking completed successfully ",
              showConfirmButton: true,
              timer: 30000,
            });
            dispatch(setLoader(false));
          } else {
            Swal.fire("Try Again", "", "error");
            dispatch(setLoader(false));
          }
        }
      }
    } catch (e) {
      console.log("saga payment error -- ", e.toString());
    }
  };

  return (
    <>
      <div className="mb-1">
        <PaymentForm
          applicationId="sandbox-sq0idb-ABA4L7lMgNmf4Vxzk7M8cg"
          locationId="L6JJ2R0MBZ9G3"
          cardTokenizeResponseReceived={tokenRecieved}
        >
          <CreditCard>Pay </CreditCard>
        </PaymentForm>
      </div>
    </>
  );
};

export default PaymentPay;
