import { Modal } from "antd";
import React from "react";
import { useState } from "react";
import "./ModalCustom.css";
import PaymentPay from "../Payment/PaymentPay";
import { useParams } from "react-router-dom";

const ModalCustom = (props) => {
  const [open, setOpen] = useState(false);
  const {id} = useParams()
          // console.log("firstttt")
  console.log("kkkk",props.bookingDetail)
  return (
    <div>
      <>
        <h3
          className="request-button text-[#fff] p-[14px] text-[13px] font-bold bg-[#002fa7]"
          type="primary"
          onClick={() => setOpen(true)}
        >
          {props.ModalName}
        </h3>
        <Modal
          centered
          open={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          width={800}
          className="z-50"
        >
          <div className="flex justify-center pt-[20px]">
            <h4 className="font-bold text-[24px] leadings-[29px] pb-[20px] pt-[10px]">
              {props.ModalTitle}
            </h4>
          </div>
          <div className="payment_Modal">
            <PaymentPay  bookingID={id} data={props.bookingDetail}  />
           
          </div>
        </Modal>
      </>
    </div>
  );
};

export default ModalCustom;
