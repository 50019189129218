import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import Swal from "sweetalert2";
import Otp from "./Otp";
import { ApiCall } from "../../../Services/apis";
import Nav from "../../../components/Patient/Nav/Nav";
import { Input } from "antd";
import "../Login.css";
import PrimaryButton from "../../../components/UI/PrimaryButton";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [otpform, setOtpform] = useState(true);
  const [data, setData] = useState({
    role: "patient",
    email: "", // Initialize email state
  });
  const [emailError, setEmailError] = useState("");

  const emailvalidation = (value) => {
    if (validator.isEmail(value)) {
      setEmailError("");
    } else {
      setEmailError("Invalid Email");
    }
  };

  const handleSubmitOtp = async (e, otp) => {
    e.preventDefault();

    if (otp <= 4) {
      Swal.fire("Invalid OTP", "Try again", "error");
    } else {
      const email = data.email;
      const data = { otp, email };
      try {
        // await verifyEmail(data);
        navigate("/ResetPassword");
        localStorage.setItem("email", email);
      } catch (error) {
        Swal.fire("connection err", "Try again", "error");
      }
    }
  };

  const sendOTP = async () => {
    if (validator.isEmail(data?.email)) {
      localStorage.setItem("email", JSON.stringify(data?.email));
      // localStorage.setItem("email", data?.email);
      try {
        const res = await ApiCall({
          route: `app_api/email_verification`,
          verb: "put",
          params: data,
        });

        if (res?.status == "200") {
          setOtpform(false);
        } else {
          Swal.fire(res?.response?.message, "Try again", "error");
        }
      } catch (e) {
        Swal.fire(e?.response?.message, "Try again", "error");
      }
    } else {
      setEmailError("Invalid Email");
      Swal.fire("Enter a valid email", "Try again", "error");
    }
  };

  return (
    <>
      <Nav />

      <div className="pt-9 pb-24 md:w-[45%] mx-auto">
        <div>
          <div className="card-shadow bg-transparent rounded px-[40px] pt-[30px]">
            {otpform ? (
              <div>
                <div className="flex justify-center">
                  <div className="w-[100%]">
                    <h1 className="text-[#002fa7] text-center md:text-[38px] leading-[54px] mt-[50px] font-semibold ">
                      Forgot password?
                    </h1>
                    <h2
                      className="text-[#2F2E2E] py-[10px] text-center text-[16px] leading-[20px] mt-[20px]"
                      style={{ lineHeight: "24px" }}
                    >
                      We will send a one-time password (OTP) to the email
                      address given below. Please use the provided OTP to reset
                      your password.
                    </h2>
                  </div>
                </div>
                <div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      sendOTP();
                    }}
                  >
                    <div className="mb-3 pt-[10px]">
                      <Input
                        type="email"
                        value={data.email}
                        onChange={(e) => {
                          setEmailError("");
                          setData({ ...data, email: e.target.value });
                        }}
                        placeholder="Email address"
                        required
                        style={{
                          marginTop: "30px",
                          width: "100%",
                          height: "42px",
                          borderRadius: "10px",
                        }}
                      />
                      {emailError && (
                        <span
                          style={{
                            fontWeight: "light",
                            color: "red",
                          }}
                        >
                          {emailError}
                        </span>
                      )}
                    </div>

                    <div className=" pt-4 pb-8 flex justify-center pb-7 ">
                      <PrimaryButton>Send OTP</PrimaryButton>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <div>
                <Otp
                  setOtpform={setOtpform}
                  handleSubmitOtp={handleSubmitOtp}
                  email={data.email}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
