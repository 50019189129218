import React from "react";
import Nav from "../../components/Patient/Nav/Nav";
import Prescription from "../../components/Patient/Prescription/Prescription";

const PrescriptionMain = () => {
  return (
    <div>
      <Nav />
      <Prescription />
    </div>
  );
};

export default PrescriptionMain;
