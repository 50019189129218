import React from "react";
import { useState } from "react";
import "./DetailsPage.css";
import UserProfile from "../../assets/Images/userProfile.svg";

const PersonalInformation = ({ bookingDetail }) => {
  console.log("data in personal information", bookingDetail);

  return (
    <>
      <div className="personal-info">
        <div className="image-cont">
          <img
            src={
              bookingDetail?.personal_info?.user_id?.profile_image ||
              UserProfile
            }
            className="profile-image-user"
          />
        </div>
        <div className="name-sec sm:w-[40%]">
          <div className="md:flex flex md:gap-3">
            <h3 className="questionss">Full Name:</h3>
            <p className="answer">
              {bookingDetail?.personal_info?.user_id?.first_name}{" "}
              {bookingDetail?.personal_info?.user_id?.last_name}{" "}
            </p>
          </div>
          <div className="md:flex flex md:gap-3  ">
            <h3 className="questionss">Email Address:</h3>
            <p className="answer">
              {bookingDetail?.personal_info?.user_id?.email}
            </p>
          </div>
          <div className="md:flex flex md:gap-3">
            <h3 className="questionss">Gender:</h3>
            <p className="answer">{bookingDetail?.personal_info?.gender}</p>
          </div>
          {/* <div className="md:flex md:justify-between flex md:pt-[10px] md:gap-3">
            <h3 className="questionss">Date of birth:</h3>
            <p className="answer">
              {bookingDetail?.personal_info?.user_id?.dob}
            </p>
          </div> */}
          <div className="flex flex md:pt-[10px] md:gap-3 ">
            <h3 className="questionss ">Geo Location:</h3>
            <p className="answer ">
              {bookingDetail?.personal_info?.location?.address}{" "}
            </p>
          </div>
          <div className="md:flex flex   ">
            <h3 className="questionss ">Reachedable Address:</h3>
            <p className="answer ml-[2.5%]">
              {bookingDetail?.personal_info?.japan_email}
            </p>
          </div>
          {/* <div className="md:flex md:justify-between flex  md:pt-[10px] md:gap-3">
                <h3 className="questionss">Nationality:</h3>
                <p className="answer">Pakistan</p>
              </div> */}
        </div>
        <div className="name-sec">
          <div className="md:flex flex md:pt-[10px] md:gap-3 ">
            <h3 className="questionss">Phone Number:</h3>
            <p className="answer">
              {bookingDetail?.personal_info?.phone_number}
            </p>
          </div>
          <div className="md:flex flex  ">
            <h3 className="questionss">Date of birth:</h3>
            <p className="answer">
              {bookingDetail?.personal_info?.user_id?.dob}
            </p>
          </div>

          {/* <div className="flex  md:pt-[10px] md:gap-3">
                <h3 className="questionss">Medical issues:</h3>
                <p className="answer"></p>
              </div> */}
        </div>
      </div>
    </>
  );
};

export default PersonalInformation;
