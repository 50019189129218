import React from "react";
import "./PrimaryButton.scss";

const PrimaryButton = ({ className, icon, children, ...props }) => {
  return (
    <button
      {...props}
      className={`aco-primary-button ${className ? className : ""}`}
    >
      <span>{children}</span> {icon ? icon : null}
    </button>
  );
};

export default PrimaryButton;
