import { all } from "redux-saga/effects";
import {
  GetSingleUserSaga,
  loginSaga,
  signUpGuestSaga,
  signUpSaga,
  signUpUserSaga,
  UpdateUserProfileSaga,
} from "./AuthSaga";
export function* rootSaga() {
  yield all([
    loginSaga(),
    signUpSaga(),
    signUpUserSaga(),
    GetSingleUserSaga(),
    UpdateUserProfileSaga(),
    signUpGuestSaga(),
  ]);
}
