import React, { Component } from "react";
import { Loader } from "@googlemaps/js-api-loader";

class NavigationMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      start: {
        lng: props?.patientLocation?.[0],
        lat: props?.patientLocation?.[1],
      },
      end: {
        lng: props?.pharmacyLocation?.[0],
        lat: props?.pharmacyLocation?.[1],
      },
      map: null,
      directionsService: null,
      directionsRenderer: null,
    };

    const loader = new Loader({
      apiKey: "AIzaSyBin-Ev0BM_6vXhxdsBmzAHl91Qje_7RBs",
      version: "weekly",
      libraries: ["places"],
    });

    console.log(this.state, " state ");

    loader.load().then(() => {
      const { start } = this.state;
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: start,
        zoom: 14,
      });
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer();
      directionsRenderer.setMap(map);
      this.setState({ map, directionsService, directionsRenderer }, () => {
        this.calculateRoute();
      });
    });
  }

  calculateRoute() {
    const { start, end, directionsService, directionsRenderer } = this.state;
    directionsService.route(
      {
        origin: start,
        destination: end,
        travelMode: "DRIVING",
      },
      (response, status) => {
        if (status === "OK") {
          directionsRenderer.setDirections(response);
        } else {
          console.error(`Directions request failed due to ${status}`);
        }
      }
    );
  }

  render() {
    return (
      <div
        id="map"
        style={{ height: "300px", width: "95%", paddingTop: "10px" }}
      ></div>
    );
  }
}

export default NavigationMap;
