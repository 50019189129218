import React from "react";
import MyConsultation from "../../../components/Patient/MyConsultation/MyConsultation";
import Nav from "../../../components/Patient/Nav/Nav";

const ConsultationMain = () => {
  return (
    <div>
      <Nav />
      <MyConsultation />
    </div>
  );
};

export default ConsultationMain;
