import React from 'react'
import ConsulHeader from './ConsulHeader'
import './consulContent.css'
import ConsulDetails from './ConsulDetails'
import DocDetails from './DocDetails'
import TimeLineConsul from './TimeLineConsul'

const ConsultationsContent = () => {
  return (
    <div className='consultationContentMain'>
      <div className='boxesContent'>
            <div className='firstBoxConsul'>
                  <h5>Status</h5>
                  <p>
                  Video appointment has been booked and awaiting for the day of appointment.
                  </p>
            </div>
            <div className='secondBoxConsul'>
                  <h5>Fees</h5>
                  <p>
                        15000
                        <span>Yen</span></p>
            </div>

      </div>
      <ConsulHeader />
      {/* <TimeLineConsul /> */}
      <ConsulDetails />
      <DocDetails />

    </div>
  )
}

export default ConsultationsContent