import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";

import Nav from "../../../components/Patient/Nav/Nav";
import socket from "../../messaging/socketConnection";
import { ApiCall } from "../../../Services/apis";

import "./Notifications.css";
import { useFaviconNotification } from "../../../helpers/FaviconNotificationContext";

const getCreationTimeFromId = (mongoDBId) => {
  const timestamp = parseInt(mongoDBId.substring(0, 8), 16) * 1000; // Convert to milliseconds
  const date = new Date(timestamp);
  return date;
};

const Notifications = () => {
  const user = useSelector((state) => state.auth.userData);
  const token = useSelector((state) => state.auth.userToken);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const navigate = useNavigate();
  const [favicon, setFavicon] = useFaviconNotification();

  useEffect(() => {
    const getData = async () => {
      const res = await ApiCall({
        route: `chat/get_notifications`,
        token: token,
        verb: "get",
      });
      if (res.status === 200) {
        // console.log(res.response, "get all notifications");
        setNotifications(res?.response?.notifications);
      }
    };
    getData();
  }, []);

  const deleteNotification = async (id) => {
    const res = await ApiCall({
      route: `chat/delete_notification/${id}`,
      token: token,
      verb: "get",
    });
    if (res.status === 200) {
      setNotifications(notifications.filter((n) => n._id !== id));
    }
  };

  const deleteAllNotifications = async () => {
    const res = await ApiCall({
      route: `chat/delete_all_notifications`,
      token: token,
      verb: "get",
    });
    if (res.status === 200) {
      setNotifications([]);
    }
  };

  const markAllNotificationsAsRead = async () => {
    const res = await ApiCall({
      route: `chat/mark_all_notifications_as_read`,
      token: token,
      verb: "get",
    });
    if (res.status === 200) {
      setNotifications(res?.response?.notifications);
    }
  };

  useEffect(() => {
    socket.on("notifications", (notice) => {
      if (user?.user_id !== notice?.senderId) {
        console.log(notice, "home message");
        if (notice.role === "patient") {
          if (notice?.usedFor !== "just_notify") {
            setNotifications(notice.notifications);
          }
        }
      }
    });
    return () => {
      socket.off("notifications");
    };
  }, [socket]);

  useEffect(() => {
    let count = 0;
    notifications.forEach((notification) => {
      if (!notification?.isRead) count++;
    });

    if (count > 0) {
      setFavicon({ ...favicon, show: true, counter: count });
    } else {
      setFavicon({ ...favicon, show: false, counter: 0 });
    }
    setNotificationCount(count);
  }, [notifications]);

  if (socket) console.log("socket working");

  const markAsRead = async (id, chatId, notification) => {
    const res = await ApiCall({
      route: `chat/mark_notification_as_read/${id}`,
      token: token,
      verb: "get",
    });
    if (res.status === 200) {
      console.log("marked as read");
      if (notification?.usedFor === "chat" && chatId) {
        navigate(`/messages?selectedChatId=${chatId}`);
      } else if (notification?.usedFor === "booking") {
        navigate(`/patient/details/${notification?.bookingId}`);
      }
    }
  };

  const groupedNotifications = notifications.reduce((acc, notification) => {
    const day = getCreationTimeFromId(notification?._id).toDateString();

    acc[day] = acc[day] || [];
    acc[day].push(notification);

    return acc;
  }, {});

  console.log(groupedNotifications, "notifications");
  return (
    <div>
      <Nav />
      <div className="notifications-page-container">
        <div className="notificactions-page-header">
          <div className="page-header-left">
            <h2>Notifications</h2>
            <p>
              You have{" "}
              {notificationCount > 0 ? <span>{notificationCount}</span> : "no"}{" "}
              unread notificaction
              {notificationCount !== 1 && "s"}
            </p>
          </div>
          <div className="page-header-right">
            <button
              title="Delete all"
              className="delete-all"
              onClick={deleteAllNotifications}
            >
              Delete
            </button>
            <button
              title="Mark all as read"
              className="read-all"
              onClick={markAllNotificationsAsRead}
            >
              Read All
            </button>
          </div>
        </div>
        <div className="notifications-page-bottom">
          <div className="all-notifications-container-on-page">
            {Object.keys(groupedNotifications).length > 0
              ? Object.entries(groupedNotifications).map(
                  ([day, notifications]) => (
                    <>
                      <h6 className="current-day-name">{day}</h6>
                      <div className="single-day-group-notifications">
                        {notifications.map((notification) => (
                          <div
                            className="container-single"
                            key={notification._id}
                          >
                            <div
                              className={
                                notification.isRead
                                  ? "notification-item-on-page"
                                  : "notification-item-on-page unread-item"
                              }
                              onClick={async () => {
                                await markAsRead(
                                  notification?._id,
                                  notification?.chatId,
                                  notification
                                );
                              }}
                            >
                              <span>
                                {notification?.isRead ? "" : "Unread message"}
                              </span>
                              <p>{notification?.notice}</p>
                              {notification?.message && (
                                <p className="single-notification-message">
                                  Message: {notification?.message}
                                </p>
                              )}
                            </div>{" "}
                            <CloseOutlined
                              className="close-icon-notification"
                              onClick={async () =>
                                deleteNotification(notification._id)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  )
                )
              : "No notifications"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
