import React from "react";
import UserProfile from "../../assets/Images/userProfile.svg";

const DoctorDetailsInformation = ({ bookingDetail }) => {
  console.log("doctor details in doc section =>", bookingDetail);
  return (
    
    <>
      <div className="personal-info">
        <div className="image-cont">
          <img
            src={bookingDetail?.doctor_id?.profile_image || UserProfile}
            className="profile-image-user"
          />
        </div>
        <div className="name-sec">
          <div className="md:flex  flex  md:pt-[10px] md:gap-3">
            <h3 className="questionss">Full Name:</h3>
            <p className="answer">
              {bookingDetail?.doctor_id?.first_name}{" "}
              {bookingDetail?.doctor_id?.last_name}{" "}
            </p>
          </div>
          <div className="md:flex  flex md:pt-[10px] md:gap-3  ">
            <h3 className="questionss">Email Address:</h3>
            <p className="answer">{bookingDetail?.doctor_id?.email}</p>
          </div>

          {/* <div className="md:flex md:justify-between flex md:pt-[10px] md:gap-3">
                <h3 className="questionss">Date of birth:</h3>
                <p className="answer">{bookingDetail?.doctor_id?.dob}</p>
              </div> */}
          {/* <div className="flex md:pt-[10px] md:gap-3 ">
                <h3 className="questionss ">Email:</h3>
                <p className="answer">{bookingDetail?.doctor_id?.email} </p>
              </div> */}
          <div className="name-sec">
            <div className="md:flex  flex md:pt-[10px] md:gap-3   ">
              <h3 className="questionss">Phone Number:</h3>
              <p className="answer">{bookingDetail?.doctor_id?.phone_number}</p>
            </div>
          
          </div>
          <div className="name-sec">
            <div className="grid grid-cols-4 gap-2">
              <h3 className="questionss">Speciality:</h3>
               { bookingDetail?.doctor_id?.deseases.map((data)=>(
                    <p className="diseases ">{data?.desease_name+","}</p>      
              ))}
            </div>
          
          </div>

          {/* <div className="flex  md:pt-[10px] md:gap-3">
                <h3 className="questionss">Medical issues:</h3>
                <p className="answer"></p>
              </div> */}
        </div>
      </div>
    </>
  );
};

export default DoctorDetailsInformation;
