import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import ForgotPassword from "../pages/auth/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/auth/ForgotPassword/ResetPassword";
import LoginPage from "../components/Patient/Prescription/LoginPage";
import LandingPage from "../pages/Patient/LandingPage/LandingPage";
import PrescriptionMain from "../pages/Patient/PrescriptionMain";
import PrescriptionDetail from "../components/Patient/Prescription/PrescriptionDetail";

import InPersonMain from "../pages/Patient/InPersonMain/InPersonMain";
import VerifyEmail from "../pages/auth/VerifyEmail";
import DisclaimerScreen from "../components/Patient/FooterPages/DisclaimerScreen";
import TermsOfUseScreen from "../components/Patient/FooterPages/TermsOfUseScreen";
import PrivacyPolicyScreen from "../components/Patient/FooterPages/PrivacyPolicyScreen";
import VideoChat from "../../src/pages/videoChat/VideoChat";
import NotFound from "../pages/NotFound/NotFound";
import UrgentCareMain from "../pages/Patient/UrgentCareMain/UrgentCareMain";
import VideoVisitMain from "../pages/Patient/VideoAppointmentMain/VideoAppointmentMain";
const AuthRoutes = () => {
  return (
    <div>
      <Routes>
        {/* <Route path="*" exact element={<NotFound link='/login' />} /> */}

        <Route path="/" exact element={<LandingPage />} />
        <Route
          path="/prescription/:name"
          exact
          element={<PrescriptionMain />}
        />
        <Route
          path="/prescriptions/:id"
          exact
          element={<PrescriptionDetail />}
        />
        <Route path="/video-chat" exact element={<VideoChat />} />

        <Route path="/verify-email" exact element={<VerifyEmail />} />
        <Route path="/video-appointment" exact element={<VideoVisitMain />} />
        <Route path="/urgent-appointment" exact element={<UrgentCareMain />} />
        <Route path="/inPerson-appointment" exact element={<InPersonMain />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/signup" exact element={<Signup />} />
        <Route path="/forgot-password" exact element={<ForgotPassword />} />
        <Route path="/reset-password" exact element={<ResetPassword />} />
        <Route path="/login-box" exact element={<LoginPage />} />
        {/* Footer Route */}
        <Route path="/disclaimer" exact element={<DisclaimerScreen />} />
        <Route path="/termsofuse" exact element={<TermsOfUseScreen />} />
        <Route path="/privacypolicy" exact element={<PrivacyPolicyScreen />} />
      </Routes>
    </div>
  );
};

export default AuthRoutes;
