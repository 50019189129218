import React from 'react'

const ConsulHeader = () => {
  return (
    <div className='consulHeader'>
     <div className='consuleHeaderContent'>
      <h1 className='consulHeaderActive'>Timeline</h1>
      <h1 className=''>Consultation Details</h1>
      <h1 className=''>Doctor Details</h1>
      <h1 className=''>Pharmacy</h1>
      <h1 className=''>Patient Details</h1>
      <h1 className=''>Medical History</h1>
      

     </div>
    </div>
  )
}

export default ConsulHeader