import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Live, Prescription, Urgent } from "../../../../assets/Images";
import Image from "../../../../assets/Images/herosection-img.svg";
import { LeftARROW, TickIcon } from "../../../../assets/Images";
import "./HeroSection.css";
import urgentPic from "../../../../assets/Urgent.png";
import videoPic from "../../../../assets/VideoCall.png";
import inpersonPic from "../../../../assets/Inperson.png";
import Image1Hero from "../../../../assets/Images/image-1-hero.png";
import Image2Hero from "../../../../assets/Images/image-2-hero.png";
import Image3Hero from "../../../../assets/Images/image-3-hero.png";
import InfoIcon from "../../../../assets/info-icon.svg";
import Swal from "sweetalert2";
const categories = [
  {
    id: "0",
    name: "Urgent Consultation",
    description: "Talk to a Doctor Now",
    button: "Urgent Consultation",
    background: "red-color",
    picture: (
      <>
        <img
          className="other-images"
          src={urgentPic}
          alt="image"
          style={{ width: "110px" }}
        />
        <img className="tabs-image" src={Image3Hero} alt="image" />
      </>
    ),
    link: "prescription",
    disable: false,

    type: "urgent",
  },

  {
    id: "1",
    name: "Video Consultation",
    description: "Talk to a Doctor Online",
    button: "Video Consultation",
    background: "black-color",
    picture: (
      <>
        <img
          className="other-images"
          src={videoPic}
          alt="image"
          style={{ width: "110px" }}
        />
        <img className="tabs-image" src={Image2Hero} alt="image" />
      </>
    ),
    link: "prescription",
    disable: false,
    type: "video",
  },
  {
    id: "2",
    name: "In-Person Consultation",
    description: "Meet your Doctor In-Person",
    button: "Schedule Consultation",
    background: "blue-color",
    picture: (
      <>
        <img
          className="other-images"
          src={inpersonPic}
          alt="image"
          style={{ width: "110px" }}
        />
        <img className="tabs-image" src={Image1Hero} alt="image" />
      </>
    ),
    link: "prescription",
    disable: true,
    type: "in-person",
  },
];

const ticksContent = [
  "English Speaking Doctors",
  "Urgent Video Consultations",
  "Get Prescriptions at a Nearby Pharmacy",
];

function HeroSection() {
  const [appointmentType, setAppointmentType] = useState("");
  const navigate = useNavigate();

  const Type = () => {
    localStorage.setItem("appointmentType", appointmentType);
    setTimeout(() => {
      if (appointmentType) {
        navigate("/prescription");
      }
    }, 1000);
  };

  const cardClickHandler = (data) => {
    if (data.disable) {
      Swal.fire({
        position: "center",
        icon: "info",
        title: `${data.name} is coming soon!`,
        text: `Get ready for the future of healthcare. ${data.name}, bringing care to you soon! 🌐🚀`,
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      navigate(`/prescription/${data.type}`);
    }
  };

  return (
    <>
      <div className=" hero-sec widthcontainer ">
        <div className="after-hero-sec">
          <section className="md:pt-[50px] md:pt-[10px] pt-[20px]  md:pb-[100px] ">
            <div className=" md:px-[20px]   px-[20px]  md:pt-[80px] pt-[60px] ">
              <div className="row relative">
                <div className="col-lg-7 heroSectionResponsive ">
                  <div className="hero-heading  flex flex-col justify-end align-center pt-[20px] ">
                    <h2>
                      We’ve got you covered <br />
                      all across Japan
                    </h2>
                    {/* <p className="pt-[7px] w-[90%] text-justify self-center  pb-[10px]">
                      American Clinic Online is a medical service backed by the
                      American Clinic Tokyo. It provides primary care in English
                      to individuals located anywhere in Japan. Patients can
                      receive medications for minor ailments at a nearby
                      pharmacy and schedule in-person visits to the American
                      Clinic Tokyo. We do not offer initial psychiatry
                      consultations or prescribe psychiatric medication through
                      our online services. We do not accept health insurance.
                    </p> */}
                    <div className="hero-ticks-section">
                      {ticksContent?.map((el) => (
                        <div className="hero-ticks-container">
                          <TickIcon /> <p className="info-text-in-hero">{el}</p>
                        </div>
                      ))}
                    </div>
                    <div className="info-at-hero-section-container">
                      <div className="info-at-hero-section">
                        <img src={InfoIcon} />
                        <span className="info-text-in-hero">
                          We do not offer initial psychiatry consultations or
                          prescribe psychiatric medication through our online
                          services. We do not accept health insurance.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:d-flex col-lg-5  flex justify-center md:pt-[0px] pt-[20px] imgToShowInBgMobile">
                  <img className="" src={Image}></img>
                </div>
              </div>
              <div className="md:pt-[5%]  pt-[20px]">
                <div className="md:flex md:justify-center md:items-center md:w-[100%] md:space-x-3 category-box-container ">
                  {categories.map((data, index) => {
                    return (
                      <div
                        disabled={data.disable}
                        className=" md:w-[30%] w-[100%] md:pt-[0px] pt-[15px] category-box-wrapper "
                        key={data.id}
                      >
                        <div
                          className={`category-box ${
                            "category-box" + (index + 1)
                          } flex md:mt-[0px] mt-[20px] md:mb-[20px] `}
                          onClick={() => cardClickHandler(data)}
                        >
                          <div className="category-images">{data.picture}</div>
                          <div className="category-content">
                            <div className="category-card-right-content">
                              <h1 className="headingFontResponsive category-card-heading">
                                {data.name}
                              </h1>
                              <h5>{data.description}</h5>
                            </div>
                            {data?.disable ? (
                              <button
                                onClick={() =>
                                  Swal.fire({
                                    position: "center",
                                    icon: "info",
                                    title: `${data.name} is coming soon!`,
                                    text: `Get ready for the future of healthcare. ${data.name}, bringing care to you soon! 🌐🚀`,
                                    showConfirmButton: false,
                                    timer: 2000,
                                  })
                                }
                                className={`category-button  ${data.background}`}
                              >
                                {/* {data.button} */}
                                <LeftARROW />
                              </button>
                            ) : (
                              <Link to={`/prescription/${data.type}`}>
                                <button
                                  className={`category-button  ${data.background}`}
                                >
                                  {/* {data.button} */}
                                  <LeftARROW />
                                </button>
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
