import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../../../assets/Images/AmericanClinic Online.svg";
import Confirm from "../../../assets/Images/video-confirm.svg";
import { handleJoinMeeting } from "../../../helpers/helpers";
import { setCategioryId } from "../../../Redux/Actions/GeneralActions";

const VideoConfirmation = (data) => {
  const user = useSelector((state) => state.auth.userData);
  const dispatch=useDispatch()
  useEffect(()=>{
   dispatch(setCategioryId(null))
  },[])

  return (
    <div className="bg-[#edf2ff] h-[100vh]">
      <div className="w-[100%] flex justify-center md:px-[50px] px-[20px] md:py-[3vh] py-[1vh] ">
        <Link to="/">
          <img src={Logo} height="150px" width="150px" className="  "></img>
        </Link>
      </div>
      <div className="border-confirm"> </div>
      <div className="VideoBg  md:px-[150px] px-[20px] md:pt-[100px] pt-[70px] md:pb-[200px]">
        <div className="md:flex">
          <img src={Confirm}></img>

          <div className="md:ml-[50px]">
            <h1 className="confirm-title mt-[30px]">Video Call Booked!</h1>
            <p className="confirm-text md:pr-[100px] mt-[10px]">
              Your Video appointment has been scheduled for the following date
              and time. Please log on at the time scheduled.
            </p>
            {/* <p className="confirm-time  mt-[10px]">
              Date: November 14, 2022 | Time: 5:00 PM.
            </p> */}
            <Link to="/my-consultation">
              <button className="btn-confirm md:mt-[20px] mt-[10px] mb-[20px]">
                My Consultation
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoConfirmation;
