import { ACTIONS } from "../Action-types";

export const setLoader = (data) => ({
  type: ACTIONS.SET_LOADER,
  data,
});
export const setCategioryId = (data) => ({
  type: ACTIONS.SET_CATEGIORY_ID,
  data,
});

