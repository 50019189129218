import React from 'react'
import { CopyText } from '../../assets/Images'
import { VideoIconConsul } from '../../assets/Images'


const ConsultationSideBar = () => {
  return (
    <div className='mainSideCom'>
      <div className='profileBar'>
            <div className='imageNname'>
                  <img src='/images/profile-img.png' alt='image' />
                  <h3>Oliver Green</h3>
                  <p>Patient</p>
            </div>
            <div className='borderOnlyConsul'></div>
            <div className='profileDetailConsul'>
                  <div className='patientDetailsConusl'>
                        <p className='firstQ'>Gender</p>
                        <p className='firstA'>Female</p>
                  </div>
                  <div className='patientDetailsConusl'>
                        <p className='firstQ'>Date of Birth</p>
                        <p className='firstA'>9/12/2000</p>
                  </div>
                  <div className='patientDetailsConusl'>
                        <p className='firstQ'>Nationality</p>
                        <p className='firstA'>Japan</p>
                  </div>
                  <div className='btnMessageConsul'>
                        <button>Message</button>
                  </div>
            </div>
            
      </div>
      <div className='videoLinkBar'>
            <div className='VideoLinkBarContent'>
            <h3>Upcoming</h3>
            <div className='videoLinksImages'>
                  <img src='/images/profile-img.png' alt='image' className='firstImageConsul'/>
                  <img src='/images/profile-img.png' alt='image' className='secondImageConsul' />
                  <div className='videoCallIcons'>
                  <VideoIconConsul/>

                  </div>
            </div>
            <h2>
                  <span> <CopyText /> </span>
                  Copy Video Url
            </h2>
            </div>


      </div>
    </div>
  )
}

export default ConsultationSideBar