import NavigationMap from "../NavigationMap/NavigationMap";
import "./DetailsPage.css";
const Pharmacy = ({ bookingDetail, prescription }) => {
  return (
    <>
      <div className="m-[40px] p-[50px]">
        <div className="flex  pt-[10px] gap-3">
          <h3 className="questionss">Pharmacy Name:</h3>
          <p className="answerss">{prescription?.pharmacy_id?.pharmacy_name}</p>
        </div>
        <div className="flex  pt-[10px] gap-3  ">
          <h3 className="questionss">Contact No:</h3>
          <p className="answerss">{prescription?.pharmacy_id?.phone_number}</p>
        </div>
        <div className="flex  pt-[10px] gap-3">
          <h3 className="questionss">Fax Number:</h3>
          <p className="answerss">{prescription?.pharmacy_id?.fax_number}</p>
        </div>
        <div className="flex  pt-[10px] gap-3">
          <h3 className="questionss">Location:</h3>
          <p className="answerss">
            {prescription?.pharmacy_id?.location?.address}
          </p>
        </div>
        <NavigationMap
          patientLocation={bookingDetail?.personal_info?.location?.coordinates}
          pharmacyLocation={prescription?.pharmacy_id?.location?.coordinates}
        />
      </div>
    </>
  );
};

export default Pharmacy;
