import { ACTIONS } from "../Action-types/index";

export const loginRequest = (data) => ({
  type: ACTIONS.LOGIN,
  data,
});
export const setLoginData = (data) => ({
  type: ACTIONS.FETCH_LOGIN_DATA,
  data,
});
export const signUpRequest = (data) => ({
  type: ACTIONS.SIGN_UP,
  data,
});
export const signUpGuestRequest = (data) => ({
  type: ACTIONS.SIGN_UP_GUEST,
  data,
});
export const startSignUpAsGuest = (data) => ({
  type: ACTIONS.START_SIGN_UP_AS_GUEST,
  data,
});
export const signUpUserRequest = (data) => ({
  type: ACTIONS.SIGN_UP_User,
  data,
});
export const logout = (data) => ({
  type: ACTIONS.LOGOUT,
  data,
});
export const getSingleUser = (data) => ({
  type: ACTIONS.GET_SINGLE_USER,
  data,
});
export const setSingleUser = (data) => ({
  type: ACTIONS.SET_SINGLE_USER,
  data,
});

export const UpdateUserProfile = (data) => ({
  type: ACTIONS.UPDATE_USER_PROFILE,
  data,
});

export const setPrescriptionRequest = (data) => ({
  type: ACTIONS.CHANGE_PRESCRIPTION_REQUEST,
  data,
});
