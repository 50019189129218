import { Checkbox } from "antd";
import React from "react";

const CheckTerms = ({ value, callback,onChange }) => {
  return (
    <div
      onClick={() => {
       
      }}
      className="flex patientSignUp p-0 items-center justify-center"
    >
      <div className="circle-border ">
        {/* <div className={value ? "circle circleActive pointer" : "circle pointer"}></div> */}
        <Checkbox onChange={onChange}
        checked={value}
        >
 <p className="remember-mee">I have read and accept the consent form</p>
        </Checkbox>
      </div>
      <div className="cursor-pointer">
        {/* <p className="remember-me">I have read and accept the consent form</p> */}
      </div>
    </div>
  );
};

export default CheckTerms;
