import React from "react";
import { Link } from "react-router-dom";
import "./FooterPages.css";

function Disclaimer() {
  return (
    <section>
      <div className="px-[20px]   ">
        <div className="h-[100%]">
          <div className="row ">
            <div className="col-lg-6 back h-[100vh]">
              <div className="ml-[20%]">
                <h2 className="login-heading">Disclaimer</h2>
                <div className="disclaimer-box my-[20px] p-[20px] sectionScroll">
                  <p>Last updated: October 30, 2022</p>
                  <p className="mt-[12px]">
                    Interpretation and Definitions
                    <br />
                    Interpretation
                  </p>
                  <p className="mt-[12px]">
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                  </p>
                  <p className="mt-[12px] bold-text">Definitions</p>
                  <p>For the purposes of this Disclaimer:</p>
                  <p className="mt-[8px]">
                    Company (referred to as either "the Company", "We", "Us" or
                    "Our" in this Disclaimer) refers to American Clinic Tokyo,
                    3F, 1-7-4 Akasaka, Minatoku, Tokyo 107-0052.
                  </p>
                  <p className="mt-[8px]">Service refers to the Application.</p>
                  <p className="mt-[8px]">
                    You means the individual accessing the Service, or the
                    company, or other legal entity on behalf of which such
                    individual is accessing or using the Service, as applicable.
                  </p>
                  <p className="mt-[8px]">
                    Application means the software program provided by the
                    Company downloaded by You on any electronic device named
                    AmericanClinicOnline.
                  </p>
                  <h3 className="mt-[8px] bold-text">Disclaimer</h3>
                  <p className="">
                    The information contained on the Service is for general
                    information purposes only.
                  </p>
                  <p className="mt-[8px]">
                    The Company assumes no responsibility for errors or
                    omissions in the contents of the Service.
                  </p>
                  <p className="mt-[8px]">
                    In no event shall the Company be liable for any special,
                    direct, indirect, consequential, or incidental damages or
                    any damages whatsoever, whether in an action of contract,
                    negligence or other tort, arising out of or in connection
                    with the use of the Service or the contents of the Service.
                    The Company reserves the right to make additions, deletions,
                    or modifications to the contents on the Service at any time
                    without prior notice.
                  </p>
                  <p className="mt-[8px]">
                    The Company does not warrant that the Service is free of
                    viruses or other harmful components.
                  </p>
                  <p className="mt-[8px] bold-text">
                    Medical Information Disclaimer
                  </p>
                  <p>
                    The information about health provided by the Service is not
                    intended to diagnose, treat, cure or prevent disease.
                    Products, services, information and other content provided
                    by the Service, including information linking to third-party
                    websites are provided for informational purposes only.
                  </p>
                  <p className="mt-[8px]">
                    Information offered by the Service is not comprehensive and
                    does not cover all diseases, ailments, physical conditions
                    or their treatment.
                  </p>
                  <p className="mt-[8px]">
                    Individuals are different and may react differently to
                    different products. Comments made on the Service by
                    employees or other users are strictly their own personal
                    views made in their own personal capacity and are not claims
                    made by the Company nor do they represent the position or
                    view of the Company.
                  </p>
                  <p className="mt-[8px]">
                    The Company is not liable for any information provided by
                    the Service with regard to recommendations regarding
                    supplements for any health purposes.
                  </p>
                  <p className="mt-[8px]">
                    The Company makes no guarantee or warranty with respect to
                    any products or services sold. The Company is not
                    responsible for any damages for information or services
                    provided even if the Company has been advised of the
                    possibility of damages.
                  </p>
                  <p className="mt-[8px] bold-text">
                    External Links Disclaimer
                  </p>
                  <p>
                    The Service may contain links to external websites that are
                    not provided or maintained by or in any way affiliated with
                    the Company.
                  </p>
                  <p className="mt-[8px]">
                    Please note that the Company does not guarantee the
                    accuracy, relevance, timeliness, or completeness of any
                    information on these external websites.
                  </p>
                  <p className="mt-[8px] bold-text">
                    Errors and Omissions Disclaimer
                  </p>
                  <p>
                    The information given by the Service is for general guidance
                    on matters of interest only. Even if the Company takes every
                    precaution to insure that the content of the Service is both
                    current and accurate, errors can occur. Plus, given the
                    changing nature of laws, rules and regulations, there may be
                    delays, omissions or inaccuracies in the information
                    contained on the Service.
                  </p>
                  <p className="mt-[8px]">
                    The Company is not responsible for any errors or omissions,
                    or for the results obtained from the use of this
                    information.
                  </p>
                  <p className="mt-[8px] bold-text">Fair Use Disclaimer</p>
                  <p>
                    The Company may use copyrighted material which has not
                    always been specifically authorized by the copyright owner.
                    The Company is making such material available for criticism,
                    comment, news reporting, teaching, scholarship, or research.
                  </p>
                  <p className="mt-[8px]">
                    The Company believes this constitutes a "fair use" of any
                    such copyrighted material as provided for in section 107 of
                    the United States Copyright law.
                  </p>
                  <p className="mt-[8px]">
                    If You wish to use copyrighted material from the Service for
                    your own purposes that go beyond fair use, You must obtain
                    permission from the copyright owner.
                  </p>
                  <p className="mt-[8px] bold-text">
                    Views Expressed Disclaimer
                  </p>
                  <p>
                    The Service may contain views and opinions which are those
                    of the authors and do not necessarily reflect the official
                    policy or position of any other author, agency,
                    organization, employer or company, including the Company.
                  </p>
                  <p className="mt-[8px]">
                    Comments published by users are their sole responsibility
                    and the users will take full responsibility, liability and
                    blame for any libel or litigation that results from
                    something written in or as a direct result of something
                    written in a comment. The Company is not liable for any
                    comment published by users and reserves the right to delete
                    any comment for any reason whatsoever.
                  </p>
                  <p className="mt-[8px] bold-text">
                    No Responsibility Disclaimer
                  </p>
                  <p>
                    The information on the Service is provided with the
                    understanding that the Company is not herein engaged in
                    rendering legal, accounting, tax, or other professional
                    advice and services. As such, it should not be used as a
                    substitute for consultation with professional accounting,
                    tax, legal or other competent advisers.
                  </p>
                  <p className="mt-[8px]">
                    In no event shall the Company or its suppliers be liable for
                    any special, incidental, indirect, or consequential damages
                    whatsoever arising out of or in connection with your access
                    or use or inability to access or use the Service.
                  </p>
                  <p className="mt-[8px] bold-text">
                    "Use at Your Own Risk" Disclaimer
                  </p>
                  <p>
                    All information in the Service is provided "as is", with no
                    guarantee of completeness, accuracy, timeliness or of the
                    results obtained from the use of this information, and
                    without warranty of any kind, express or implied, including,
                    but not limited to warranties of performance,
                    merchantability and fitness for a particular purpose.
                  </p>
                  <p className="mt-[8px]">
                    The Company will not be liable to You or anyone else for any
                    decision made or action taken in reliance on the information
                    given by the Service or for any consequential, special or
                    similar damages, even if advised of the possibility of such
                    damages.
                  </p>
                  <p className="mt-[12px] bold-text">Contact Us</p>
                  <p>
                    If you have any questions about this Disclaimer, You can
                    contact Us:
                  </p>
                  <p className="mt-[12px]">
                    By email:{" "}
                    <a href="https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=GTvVlcSMSqctFrPBFcgMPfnHMhhDFLVHxHFtgwwbvbnbmFqdxcfdrqShKQRmdpjCZZVtQPHsKHvKn" target="_blank" style={{color:'#002af'}}>
                      {" "}
                      info@americanclinicotokyo.com
                    </a>
                  </p>
                </div>

                <div className="flex justify-between mb-[5px]">
                  <div className="remember flex ml-[5px]">
                    <input
                      type="checkbox"
                      id="remember"
                      className="bg-transparent rounded-r-lg border border-gray-600  text-sm  h-[23px] w-[23px]"
                    ></input>
                    <p className="remember-me">
                      I have read and accept the disclaimer
                    </p>
                  </div>
                </div>
                <Link to="/">
                  <button className="login-btn ">Submit</button>
                </Link>
              </div>
            </div>
            <div
              className="col-lg-6 md:block hidden back-color h-[100vh] "
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link to="/">
                <img src="/images/AmericanClinic Online.png"></img>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Disclaimer;
