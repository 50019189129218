import { DatePicker, Modal } from "antd";
import React, { useState } from "react";
import "./ReportModal.scss";
import { ApiCall } from "../../Services/apis";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const ReportModal = ({ open, close, bookingId, handleLogout }) => {
  const token = useSelector((state) => state?.auth?.userToken);
  const [current, setCurrent] = useState(0);
  const [data, setData] = useState({
    availability: "",
    feedback: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const requestReportAndReschedule = async (body) => {
    try {
      const res = await ApiCall({
        route: `booking/report_and_reschedule_urgent_care/${bookingId}`,
        token: token,
        verb: "post",
        params: body,
      });

      if (res?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Your report has been submitted successfully.",
          text: "Doctor will contact you soon during your available timings. Thank you!",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          close();
          handleLogout(true, "/my-consultation");
        });
        console.log(`${res?.response} "suc", "Successfuly"`);
      } else {
        Swal.fire({
          icon: "error",
          title: res?.response?.message || "Something went wrong.",
          text: "We couldn't submit your feedback.",
          showConfirmButton: false,
          timer: 3000,
        });
        console.log(`${res?.response} "Try again", "error"`);
      }
    } catch (error) {
      Swal.fire(error?.response, "Try again", "error");
    }
  };

  const checkAvailability = () => {
    if (!data.availability || data?.availability?.trim() === "") {
      Swal.fire({
        icon: "error",
        title: "Please provide your availability.",
        text: "Please try again.",
        showConfirmButton: false,
        timer: 1000,
      });
      return false;
    } else {
      return true;
    }
  };

  const checkFeedback = () => {
    if (!data.feedback || data?.feedback?.trim() === "") {
      Swal.fire({
        icon: "error",
        title: "Please provide your feedback.",
        text: "Please try again.",
        showConfirmButton: false,
        timer: 1000,
      });
      return false;
    } else {
      return true;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!checkAvailability()) {
      return;
    }
    if (!checkFeedback()) {
      return;
    }
    await requestReportAndReschedule(data);
  };

  return (
    <Modal
      centered
      open={open}
      onCancel={close}
      onOk={close}
      maskClosable={false}
    >
      {current === 0 ? (
        <div className="video-report-modal-container">
          <h2>We apologize for the inconvenience!</h2>
          <p>
            Our apologies; the doctor is currently not present for your
            consultation. Thank you for your patience.
          </p>
          <h6>
            Please share your availability, and we'll arrange the doctor's
            schedule accordingly.
          </h6>
          <div className="report-modal-form-container">
            <textarea
              autoFocus
              name="availability"
              value={data.availability}
              onChange={handleChange}
              placeholder="Your availability timings..."
            />{" "}
            <div className="buttons-container">
              <button className="red-button" onClick={close}>
                Cancel
              </button>
              <button
                onClick={() => {
                  if (!checkAvailability()) {
                    return;
                  }
                  setCurrent(1);
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="video-report-modal-container">
          <h2>Please provide feedback.</h2>
          <p>
            We value your feedback and are committed to enhancing our services
            based on your experience.
          </p>
          <div className="report-modal-form-container">
            <textarea
              autoFocus
              name="feedback"
              value={data.feedback}
              onChange={handleChange}
              placeholder="Share your thoughts here..."
            />
            <div className="buttons-container">
              <button className="red-button" onClick={() => setCurrent(0)}>
                Back
              </button>
              <button onClick={onSubmit}>Submit</button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ReportModal;
