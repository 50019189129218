import React, { useEffect, useState } from 'react';

function CustomMap() {
  const [map, setMap] = useState(null);

  useEffect(() => {
    const loadGoogleMaps = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBin-Ev0BM_6vXhxdsBmzAHl91Qje_7RBs`;
      script.onload = () => {
        const googleMaps = window.google.maps;
        const mapDiv = document.getElementById('map');
        const map = new googleMaps.Map(mapDiv, {
          center: { lat: 37.7749, lng: -122.4194 },
          zoom: 13,
        });
        setMap(map);
      };
      document.head.appendChild(script);
    };
    loadGoogleMaps();
  }, []);
  useEffect(() => {
    if (map) {
      // Perform any additional operations on the map
      // or add markers, polygons, etc.
    }else{
      return <div>Loading...</div>;
    }
  }, [map]);

  // if (!map) {
  //   return <div>Loading...</div>;
  // }

  return <div id="map" style={{ height: '500px' }}></div>;
}

export default CustomMap;
