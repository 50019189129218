import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Email } from "../../../assets/Images";

const Schedule = ({ data, setData, validateSchedule, scheduleError }) => {
  console.log("schedule errors", scheduleError);
  const [timeSlot, setTimeSlot] = useState("");
  const [value, onChange] = useState(new Date());

  const tileDisabled = ({ date }) => {
    return date < new Date();
  };

  return (
    <>
      <div className="fourth-step"></div>
      <div className="w-[100%] md:flex justify-between personal-bgg">
        <div className="md:w-[50%] ">
          <div className="md:pl-[120px] pl-[20px] personal mt-[60px]">
            <h1>Schedule</h1>
            {/* <p className="md:pr-[50%] pr-[20px]">
              For safety reasons, the patient must be present to answer personal
              health questions. A parent or legal guardian must be present with
              a minor at all times.
            </p> */}
            {/* <div className="flex mt-[40px] border-b">
              <h6>Email reminders</h6>
              <Email className=" ml-[10px]" />
            </div> */}
          </div>
          {/* <img src={ScheduleBg} className="md:block hidden absolute bottom-0" /> */}
        </div>
        <div className="md:w-[50%] md:pr-[100px] md:pl-[0px] pr-[20px] pl-[20px] mt-[10px]">
          <h1 className="schedule-heading">Select Appointment Date</h1>
          <div className="my-[10px] w-[100%]">
            <Calendar
              onChange={(e) =>
                setData({ ...data, appointment_date: new Date(e) })
              }
              value={data?.appointment_date}
              tileDisabled={tileDisabled}
              
            />
          </div>
          <h1 className="schedule-heading">Preferred Time Slot</h1>
          <div className=" mt-[15px] md:mb-[0px] mb-[10px] flex">
            {/* <form onChange={validateSchedule()}> */}
            <button
              className={
                timeSlot == "morning" ? `selected-time-slot` : `time-slot-btn`
              }
              onClick={() => {
                setTimeSlot("morning");
                setData({ ...data, batch: "morning" });
              }}
            >
              Morning
            </button>
            <button
              className={
                timeSlot == "evening"
                  ? `selected-time-slot ml-[20px] `
                  : `time-slot-btn ml-[20px] `
              }
              onClick={() => {
                setTimeSlot("evening");
                setData({ ...data, batch: "evening" });
              }}
            >
              Evening
            </button>
          </div>
        
        </div>
      </div>
    </>
  );
};

export default Schedule;
