export const ACTIONS = {
  LOGOUT: "LOGOUT",
  SET_LOADER: "SET_LOADER",
  LOGIN: "LOGIN",
  SIGN_UP: "SIGN_UP",
  SIGN_UP_GUEST: "SIGN_UP_GUEST",
  START_SIGN_UP_AS_GUEST: "START_SIGN_UP_AS_GUEST",
  FETCH_LOGIN_DATA: "FETCH_LOGIN_DATA",
  FETCH_SIGN_UP: "FETCH_SIGN_UP",
  SIGN_UP_User: "SIGN_UP_User",
  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
  GET_SINGLE_USER: "GET_SINGLE_USER",
  SET_SINGLE_USER: "SET_SINGLE_USER",
  SET_CATEGIORY_ID: "SET_CATEGIORY_ID",
  SET_ONLINE_USERS: "SET_ONLINE_USERS",
  ADD_ONLINE_USER: "ADD_ONLINE_USER",
  REMOVE_ONLINE_USER: "REMOVE_ONLINE_USER",
  CHANGE_PRESCRIPTION_REQUEST: "CHANGE_PRESCRIPTION_REQUEST",
};
