import { act } from "react-dom/test-utils";
import { ACTIONS } from "../Action-types";

const initialState = {
  userToken: null,
  userData: null,
  otpToken: null,
  categioryId: null,
  prescriptionRequest: false,
  askedSignupAsGuest: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_LOGIN_DATA:
      return {
        ...state,
        userToken: action.data?.token,
        userData: action.data?.user,
        prescriptionRequest: action.data?.prescriptionRequest ? true : false,
      };
    case ACTIONS.SET_CATEGIORY_ID:
      return {
        ...state,
        categioryId: action.data,
      };
    case ACTIONS.FETCH_SIGN_UP:
      return {
        ...state,

        userToken: action.data?.token,
        userData: action.data?.user,
      };

    case ACTIONS.FETCH_OTP:
      return {
        ...state,
        otpToken: action.data.token,
      };
    case ACTIONS.SET_SINGLE_USER:
      return {
        ...state,
        userData: action.data,
      };
    case ACTIONS.LOGOUT:
      return {
        ...state,
        userToken: null,
        userData: null,
        otpToken: null,
      };
    case ACTIONS.CHANGE_PRESCRIPTION_REQUEST:
      return {
        ...state,
        prescriptionRequest: action?.data?.prescriptionRequest ? true : false,
      };
    case ACTIONS.START_SIGN_UP_AS_GUEST:
      return {
        ...state,
        askedSignupAsGuest: action.data.status,
      };
    default:
      return state;
  }
};

export default authReducer;
