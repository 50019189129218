import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../Patient/Footer/Footer";
import Nav from "../Patient/Nav/Nav";

const AppWrapper = (props) => {

  return (
    <>
      
        <Nav />  
           
      
      {props.children}
      <Footer />
    </>
  );
};

export default AppWrapper;
