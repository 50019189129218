import React, { useEffect, useState } from "react";
import Nav from "../Nav/Nav";
import "./Prescription.css";
import DiseaseDetail from "../../../assets/Images/disease-detail.png";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../../Services/apis";
import { useNavigate, useParams } from "react-router-dom";
import LoginPage from "./LoginPage";
import { setLoader } from "../../../Redux/Actions/GeneralActions";
import { startSignUpAsGuest } from "../../../Redux/Actions/AuthActions";

const PrescriptionDetail = () => {
  const user = useSelector((state) => state.auth.userData);
  const token = useSelector((state) => state.auth.userToken);
  const askedSignupAsGuest = useSelector(
    (state) => state.auth.askedSignupAsGuest
  );
  const dease = useSelector((state) => state.auth.categioryId);
  const dispatch = useDispatch();
  const [details, setDetails] = useState();
  console.log("details", details);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const appointmentType = localStorage.getItem("appointmentType");
  // console.log(appointmentType);
  let { id } = useParams();
  useEffect(() => {
    dispatch(setLoader(true));
    async function fetchData() {
      const res = await ApiCall({
        route: `desease/detail_category/${id}`,
        verb: "get",
        token: token,
      });
      if (res?.status == 200) {
        dispatch(setLoader(false));
        setDetails(res?.response?.category);
      } else {
        console.log("error", res?.response?.message);
        dispatch(setLoader(false));
      }
    }
    fetchData();
  }, []);

  const navigation = () => {
    if (user) {
      if (appointmentType == "video") {
        navigate("/video-appointment");
      } else if (appointmentType == "in-person") {
        navigate("/inPerson-appointment");
      } else {
        navigate("/urgent-appointment");
      }
    } else {
      if (!askedSignupAsGuest) {
        navigate("/login-box");
      } else {
        dispatch(startSignUpAsGuest({ status: false }));
        if (dease?.type == "video") {
          navigate(`/video-appointment`);
        } else if (dease?.type == "urgent") {
          navigate(`/urgent-appointment`);
        } else {
          navigate(`/inPerson-appointment`);
        }
      }
    }
  };

  return (
    <>
      <Nav />
      <section>
        <div className="container mx-auto md:mx-[150px] mt-[40px]">
          <div className=" md:px-[50px] px-[10px] md:py-[20px] md:mx-[0px] mx-[10px] detail-page mainCard md:flex  md:mt-[8%] ">
            <div className="md:w-[60%] ">
              <h1 className="pt-[15px]">{details?.category_name}</h1>
              <p style={{ letterSpacing: "0.3px" }}>{details?.description}</p>
              <button
                class="btn-get-started"
                onClick={() => {
                  navigation();
                }}
              >
                Get Started
              </button>
            </div>
            <div className="md:w-[40%] flex justify-end">
              <img
                src={details?.image ? details?.image : DiseaseDetail}
                alt="disease"
                className="md:h-[150px] h-[100px] object-cover md:w-[250px] rounded-lg pb-[6px]"
              />
            </div>
          </div>
          <div className="flex md:flex-row flex-col justify-between items-center h-auto ">
            <div className="detail-text-box pb-[10px] pt-[20px] pr-[15px] mt-[20px] h-[200px]  md:h-[270px] overflow-y-auto">
              <center>
                <h3>Overview</h3>
              </center>
              <ul
                style={{
                  listStyleType: "disc",
                  paddingLeft: "25px",
                  listStyle: "none",
                }}
              >
                <li className="mt-[14px]" style={{ letterSpacing: "0.3" }}>
                  {details?.overview}
                </li>
              </ul>
            </div>
            <div className="detail-text-box  pb-[10px] pt-[20px] pr-[15px] mt-[20px] h-[200px] md:h-[270px] overflow-y-auto">
              <center>
                <h3>Common symptoms</h3>
              </center>
              <ul
                style={{
                  listStyleType: "disc",
                  marginTop: "10px",
                  paddingLeft: "23px",
                  listStyle: "none",
                }}
              >
                <li className="mt-[14px]">{details?.common_symptoms}</li>
              </ul>
            </div>
            <div className="detail-text-box pb-[10px] pt-[20px] pr-[15px] mt-[20px] h-[200px] md:h-[270px] overflow-y-auto">
              <center>
                <h3>Treatment</h3>
              </center>
              <ul
                style={{
                  listStyleType: "disc",
                  paddingLeft: "23px",
                  listStyle: "none",
                }}
              >
                <li className="mt-[14px]">{details?.treatment}</li>
              </ul>
            </div>
          </div>
          {show ? (
            <>
              <LoginPage detail={details} />
            </>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default PrescriptionDetail;
