import React, { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { CreditCard } from "../../../assets/Images";

import VideoPayment from "../../Payment/VideoPay";
import { useSelector } from "react-redux";
import { ApiCall } from "../../../Services/apis";

const Payment = ({ data, bookingId }) => {
  const [amount, setAmount] = useState(null);
  const diseaseInfo = useSelector((state) => state.auth);

  async function getAmount() {
    try {
      const amountResponse = await ApiCall({
        route: `price/get_price`,
        verb: "get",
      });
      console.log(amountResponse, "amount");
      setAmount(amountResponse?.response?.price?.video_call);
    } catch (e) {
      console.log(e, "amount error");
    }
  }
  useEffect(() => {
    if (!amount) {
      getAmount();
    }
  }, [amount]);
  return (
    <>
      <div className="five-step"></div>
      <div className="w-[100%] md:flex justify-between personal-bgg">
        <div className="md:w-[50%] ">
          <div className="md:pl-[120px] pl-[20px] personal mt-[60px]">
            <h1>Pay & confirm</h1>
            <p className="md:pr-[50%] pr-[20px]">
              For safety reasons, the patient must be present to answer personal
              health questions. A parent or legal guardian must be present with
              a minor at all times.
            </p>
            <div className="flex mt-[40px] border-pay">
              <h6>Secure info & transactions</h6>
              <CreditCard className=" ml-[10px]" />
            </div>
          </div>
        </div>
        <div className="md:w-[50%] md:pr-[68px] md:pl-[0px] pr-[20px] pl-[20px] h-[400px] mt-[10px] payment-container-in-steps">
          <div className="my-[10px] w-[100%]  md:px-[30px] ">
            <table class="payment-page-table-main table-bordered  ">
              <thead>
                <tr>
                  <th>Consultation Type</th>
                  <th>Condition</th>

                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Scheduled Video</td>
                  <td>{diseaseInfo?.categioryId?.category_name}</td>

                  <td>{amount} ¥</td>
                </tr>
              </tbody>
            </table>

            <VideoPayment data={data} amount={amount} />
          </div>

          <div className="md:ml-[30px] md:mt-[20px] mt-[15px] md:mb-[0px] md:pr-[25px] mb-[10px] md:flex justify-between">
            <h1 className="payment-total">Consultation fee: {amount}¥ </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
