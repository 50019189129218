import React from "react";
import Nav from "../../components/Patient/Nav/Nav";
import Messages from "../../components/Messages";

export default function MessagingPage() {
  return (
    <div>
      <Nav />
      <Messages />
    </div>
  );
}
