import React, { useState, useEffect } from "react";
import "./Prescription.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../../Services/apis";
import {
  setCategioryId,
  setLoader,
} from "../../../Redux/Actions/GeneralActions";
import { Carousel } from "antd";
import { LeftARROW } from "../../../assets/Images";
import { setPrescriptionRequest } from "../../../Redux/Actions/AuthActions";

const Prescription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.userToken);
  const loader = useSelector((state) => state.general.loader);
  const [diseases, setDiseases] = useState();
  const [categorys, setCategiorys] = useState([]);
  const [selected, setSelected] = useState("");
  const [diseaseId, setDiseasesId] = useState("");
  const [ischeck, setIsCheck] = useState(false);
  const [datalenght, setDataLenght] = useState(0);
  // console.log("category", categorys);
  let { name } = useParams();
  localStorage.setItem("appointmentType", name);
  const handleSelection = (value) => {
    setSelected(value._id);
    setCategiorys(value);
  };
  // const CheckHandler = (e) => {
  //   e.target.checked;
  // }
  const categorieId = useSelector((state) => state.auth.categioryId);

  useEffect(() => {
    dispatch(setPrescriptionRequest({ prescriptionRequest: false }));
  }, []);

  useEffect(() => {
    async function fetchData() {
      dispatch(setLoader(true));
      const res = await ApiCall({
        route: `desease/listing_desease`,
        verb: "get",
        token: token,
      });
      if (res?.status == 200) {
        dispatch(setLoader(false));
        setDiseases(res?.response?.desease);
        setCategiorys(res?.response?.desease[0]);
        setSelected(res?.response.desease[0]._id);
      } else {
        console.log("error", res?.response?.message);
        dispatch(setLoader(false));
      }
    }
    fetchData();
  }, []);
  useEffect(() => {
    setDataLenght(diseases?.length);
  }, [diseases]);
  const checkStatus = (data) => {
    if (categorieId?.deseaseId == data?._id) {
      return true;
    } else {
      return false;
    }
  };
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  return (
    !loader && (
      <section className="prescription  container mx-auto mt-[9%]">
        {/* {diseases && diseases?.length > 7 ? <Carousel autoplay><div>{diseases.map((e) => console.log(e?.desease_name))}
         </div></Carousel> : <p>condition False</p>} */}

        <div className="md:px-[120px]  mt-[50px] px-[20px]">
          <center>
            <h1>Need a Prescription? Select a condition to proceed</h1>
          </center>
        </div>
        {/* {datalenght >= 10 ? <Carousel arrows autoplay>
        <div style={{backgroundColor:'#002AFF'}}>1</div>
        <div style={{backgroundColor:'#bdbdbd'}}>2</div>
      </Carousel> : <p>not apply</p>} */}
        <section
          className="mt-md-4 bg-white border-x border-y border-t-0 rounded-5 py-2"
          id="startNow"
        >
          <div
            class="category-box-pre sm:flex active"
            data-tabName="urgent-care-sick-visit"
          >
            {diseases?.length > 0 ? (
              diseases?.map((value, index) => {
                return (
                  <>
                    <div
                      data-tabName="urgent-care-sick-visit"
                      className={` category-boxes   ${
                        selected == value?._id ? `disease-box-color` : null
                      } `}
                      key={index._id}
                      onClick={() => handleSelection(value, value._id)}
                    >
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div class="col-12">
                            <div class="col">
                              <h3 className="category-boxes-tax">
                                {value?.desease_name}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "400px",
                  textTransform: "capitalize",
                }}
              >
                No any data yet!!
              </div>
            )}
          </div>

          {/* ----------------------------------------? */}
          <div className="   b-l-w[1px] md:border-gray  pt-[10px] ">
            <>
              {/* <div className="w-[100%] "> */}
              <div className="flex px-[10px] gap-[8px] text-center grid md:grid-cols-4 ">
                {categorys?.categories && categorys?.categories.length == 0 ? (
                  <p className="text-center italic ml-[1%]">No Data Yet !!</p>
                ) : (
                  categorys?.categories &&
                  categorys?.categories.map((data, i) => {
                    return (
                      <div
                        className="w-[100%] border flex rounded-[20px] bg-[#F0F0F0] text-[#0D0D0D] font-medium  cursor-pointer btnPresvrip"
                        key={i._id}
                        onClick={() => {
                          console.log("sdfafsad", categorys);
                          dispatch(
                            setCategioryId({
                              categorieId: data?._id,
                              deseaseName: categorys?.desease_name,
                              deseaseId: categorys?._id,
                              category_name: data?.category_name,
                              type: name,
                            })
                          );
                          navigate(`/prescriptions/${data?._id}`);
                        }}
                      >
                        <button className=" m-2">{data?.category_name}</button>
                        <span>
                          <LeftARROW />
                        </span>
                      </div>
                    );
                  })
                )}
              </div>
              {/* </div> */}
            </>
          </div>
        </section>
        {/* <div className="flex align-end justify-end mt-[100px] mr-[120px] ">
        <Link to={`/prescriptions/${diseaseId}`}>
          <button className="continue-btn">Next</button>
        </Link>
      </div> */}
      </section>
    )
  );
};
export default Prescription;
