import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import FaviconNotificationContextProvider from "./helpers/FaviconNotificationContext";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/doctor/firebase-messaging-sw.js")
    .then(function (registration) {
      console.log(
        "Service worker registration successful, scope is:",
        registration.scope
      );
    })
    .catch(function (err) {
      console.error("Service worker registration failed, error:", err);
    });
}

// Add this in your main React file (e.g., index.js)
window.deferredPrompt = null;

window.addEventListener("beforeinstallprompt", (event) => {
  event.preventDefault();
  window.deferredPrompt = event;
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <FaviconNotificationContextProvider>
        <App />
      </FaviconNotificationContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
