import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../../../assets/Images/AmericanClinic Online.svg";
import {
  handleJoinMeeting,
  handleJoinUrgentCare,
} from "../../../helpers/helpers";
import { setCategioryId } from "../../../Redux/Actions/GeneralActions";

const UrgentConfirmation = (propsData) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCategioryId(null));
  }, []);

  const navigate = useNavigate();
  const handleJoinUrgentCare = (roomId, userId, contactType) => {
    console.log("room id is this", roomId);
    navigate(
      `/video-chat?roomId=${roomId}&&name=${userId}&&type=urgent&&contactType=${contactType}`
    );
  };

  const RoomId = useParams();
  console.log("room id", RoomId);
  const user = useSelector((state) => state.auth.userData);

  return (
    <div className="bg-[#edf2ff] h-[100vh]">
      <div className="w-[100%] flex justify-center md:px-[50px] px-[20px] md:py-[3vh] py-[1vh] ">
        <Link to="/">
          <img src={Logo} height="150px" width="150px" className="  "></img>
        </Link>
      </div>
      <div className="border-confirm"> </div>
      <div className="VideoBg  md:px-[150px] px-[20px] md:pt-[100px] pt-[70px] md:pb-[200px]">
        <div className="md:flex urgent-conformation-container">
          <img className="mx-auto" src="/images/videoConfirm.png"></img>

          <div className="md:ml-[50px] w-100% flex md:block flex-col urgent-conformation-text-container">
            <h1 className="confirm-title md:text-left text-center mt-[50px]">
              Urgent Care Consultation Booked!
            </h1>
            <p className="confirm-text md:pr-[100px] md:text-left text-center mt-[10px]">
              Please enter the triage room and wait for the doctor to join the
              meeting.
            </p>

            <button
              onClick={() =>
                handleJoinUrgentCare(RoomId?.id, user?.user_id, "video")
              }
              className="btn-confirm md:mt-[20px] mx-auto mt-[10px] mb-[20px]"
            >
              Join Call
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UrgentConfirmation;
