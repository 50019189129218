import "./App.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./Redux/Store";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import MainRoutes from "./Routes/MainRoutes";
const queryClient = new QueryClient();

function App() {
  // useEffect(()=>{
  //      const script = document.createElement('script');
  //      script.src="https://sandbox.web.squarecdn.com/v1/square.js";
  //      script.type="text/javascript";
  //      document.body.appendChild(script);

  //      return () => {
  //       document.body.removeChild(script);
  //      }
  // },[])

  return (
    <div className="widthcontainer">
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <MainRoutes />
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
