import React, { useState, useEffect, useRef } from "react";
// import Toggle from '../SideDrawer/Toggle';
import { Link, NavLink, useNavigate } from "react-router-dom";
import Logo from "../../../assets/Images/AmericanClinic Online.svg";
import "./Nav.css";
import { useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../Redux/Actions/AuthActions";
import UserDp from "../../../assets/Images/user.png";
import Swal from "sweetalert2";
import AddToHomeScreen from "../../Common/AddToHome";

const Nav = () => {
  const [show, setShow] = useState(false);
  const user = useSelector((state) => state.auth.userData);
  const [transparent, setTransparent] = useState(true);
  const location = useLocation();
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();
  const path = location.pathname;

  useEffect(() => {
    console.log(user);
  }, []);
  // console.log("transparent", transparent);

  const scrollFun = () => {
    var scrollValue = window.scrollY;

    if (scrollValue > 20) setTransparent(false);
    else setTransparent(true);
  };

  // check if the path changes then transparent navBar
  useEffect(() => {
    document.addEventListener("scroll", scrollFun);

    return () => {
      document.removeEventListener("scroll", scrollFun);
    };
  }, [path]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutSide, true);
    return () => {
      document.removeEventListener("click", handleClickOutSide, true);
    };
  }, [ref]);

  const handleClickOutSide = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
    }
  };

  return (
    <>
      {/* {path == "/my-consultation" ? null : */}

      <header
        className={
          transparent === true
            ? " toolbar bgg-transparent widthcontainer"
            : " toolbar shadow widthcontainer"
        }
      >
        {/* <div>
          <AddToHomeScreen />
        </div> */}
        <nav className="toolbar-navigation widthcontainer">
          <div className="md:hidden block flex">
            <Sidebar />
            <div className="nav-line"></div>
          </div>
          <div className="toolbar-logo mobile-toolbar-logo ">
            <div onClick={() => navigate(`/`)}>
              <img
                src={Logo}
                alt="nav-logo"
                className="md:ml-[2px] cursor-pointer"
                style={{ height: "40px", cursor: "pointer" }}
              ></img>
            </div>
          </div>
          <div className="spacer"></div>
          {user ? (
            <>
              <div className="toolbar-navigation-items">
                <ul className="nav-items">
                  <li>
                    <NavLink to="/prescription/urgent">Urgent Care</NavLink>
                  </li>
                  <li>
                    <NavLink to="/prescription/video">Video Call</NavLink>
                  </li>
                  <li>
                    {/* <NavLink to="/prescription/in-person">
                    In-Person Visit
                  </NavLink> */}
                    <Link
                      onClick={() =>
                        Swal.fire({
                          position: "center",
                          icon: "info",
                          title: `In-Person Consultation is coming soon!`,
                          text: `Get ready for the future of healthcare. In-Person Consultations, bringing care to you soon! 🌐🚀`,
                          showConfirmButton: false,
                          timer: 2000,
                        })
                      }
                    >
                      In-Person Visit
                    </Link>
                  </li>
                </ul>
              </div>

              <div
                className="nav-btn   pl-[0px] ml-[15px] "
                onClick={() => setShow(!show)}
              >
                <img
                  src={user.profile_image ? user.profile_image : UserDp}
                  alt={user?.first_name}
                  height="55px"
                  width="55px"
                  style={{
                    borderRadius: "50%",
                    border: "0.5px solid #DDDDDD",
                    objectFit: "cover",
                  }}
                  className="cursor-pointer mr-[10px] h-[55px] w-[55px]"
                />
                {show ? (
                  <>
                    <div
                      ref={ref}
                      className="nav-dropdown absolute  cursor-pointer"
                    >
                      <Link to="/my-consultation">
                        <h4 className="text-[#0d0d0d] text-[12px] font-normal cursor-pointer">
                          Dashboard
                        </h4>
                      </Link>

                      <Link to="/messages">
                        <h4 className="text-[#0d0d0d] text-[12px] font-normal pt-[15px] cursor-pointer">
                          Messages
                        </h4>
                      </Link>
                      <Link to="/profile">
                        <h4 className="text-[#0d0d0d] text-[12px] font-normal pt-[15px] cursor-pointer">
                          Profile Settings
                        </h4>
                      </Link>
                      <Link to="/contact-us">
                        <h4 className="text-[#0d0d0d] text-[12px] font-normal pt-[15px] cursor-pointer">
                          Contact Us
                        </h4>
                      </Link>
                      <Link to="/notifications">
                        <h4 className="text-[#0d0d0d] text-[12px] font-normal pt-[15px] cursor-pointer">
                          Notifications
                        </h4>
                      </Link>

                      <h4
                        className="text-[#EB5757] text-[12px] font-bold pt-[15px] cursor-pointer"
                        onClick={() => {
                          Swal.fire({
                            title: "Are you sure you want to Logout?",
                            showCancelButton: true,
                            confirmButtonText: "Yes",
                            cancelButtonText: "No",
                            icon: "warning",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              dispatch(logout());
                              navigate("/");
                            }
                          });
                        }}
                      >
                        Logout
                      </h4>
                    </div>
                  </>
                ) : null}
              </div>
            </>
          ) : (
            <>
              <div className="nav-btn md:block flex pr-[10px]">
                <Link to="/login">
                  <button className="login-btn-nav max-w-[70px]">Log in</button>
                </Link>
                <Link to="/signup">
                  <button className="register-btn-nav  max-w-[85px]">
                    Sign up
                  </button>
                </Link>
              </div>
            </>
          )}
        </nav>
      </header>
    </>
  );
};

export default Nav;
