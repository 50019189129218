import React from "react";
import AppointmentCard from "./AppointmentCard";
import "./HowItWorks.css";
import UrgentCareCard from "./UrgentCareCard";
import VideoCall from "./VideoCall";

const HowItWorks = () => {
  return (
    <>
      <section
        className="md:mt-[70px] md:pt-[37rem] md:pt-[40rem] pt-[58rem] mt-[180px]"
        id="howItWorks"
      >
        <div className="md:px-[120px] px-[20px]">
          <div className="row">
            <center>
              <h1 className="works-heading" style={{ fontSize: "35px" }}>
                <span
                  style={{ fontSize: "35px", marginRight: "2px" }}
                  className="span-color"
                >
                  How
                </span>{" "}
                It Works?
              </h1>
            </center>
          </div>
          <div>
            <UrgentCareCard />

            <VideoCall />
            <AppointmentCard />
          </div>
        </div>
      </section>
    </>
  );
};

export default HowItWorks;
