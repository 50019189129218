import React from "react"

const FileIcon = () => {
  return (
    <svg
      width="13"
      height="18"
      viewBox="0 0 13 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3669 3.28289L9.84039 0.658832C9.43177 0.237027 8.87995 3.51562e-06 8.30781 3.51562e-06L2.16667 0C0.969922 0 0 1.00723 0 2.25L0.000220052 15.75C0.000220052 16.9924 0.970142 18 2.16689 18H10.8333C12.025 18 13 16.9875 13 15.75V4.87266C13 4.27852 12.7732 3.70547 12.3669 3.28289ZM11.375 15.75C11.375 16.0606 11.1325 16.3125 10.8333 16.3125H2.16734C1.86814 16.3125 1.62568 16.0606 1.62568 15.75L1.625 2.25457C1.625 1.94393 1.86753 1.69207 2.16667 1.69207H7.58333V4.5C7.58333 5.12121 8.06846 5.625 8.66667 5.625H11.3445V15.75H11.375ZM3.25 9.84375C3.25 10.3113 3.61563 10.6875 4.0625 10.6875H8.9375C9.38776 10.6875 9.75 10.3113 9.75 9.84375C9.75 9.37617 9.38776 9 8.9375 9H4.0625C3.61563 9 3.25 9.37969 3.25 9.84375ZM8.9375 12.375H4.0625C3.61563 12.375 3.25 12.7547 3.25 13.2188C3.25 13.6828 3.61393 14.0625 4.0625 14.0625H8.9375C9.38607 14.0625 9.75 13.6846 9.75 13.2188C9.75 12.7529 9.38776 12.375 8.9375 12.375Z"
        fill="#828282"
      />
    </svg>
  )
}

export default FileIcon
