import React, { useState } from "react";
import { Button, Drawer } from "antd";
import ChatInVideo from "./ChatInVideo";
import messageIcon from "../../assets/messageIcon.svg";
import ChatBox from "./ChatBox";

const ChatDrawer = (selectedChat) => {
  const [open, setOpen] = useState(false);
  console.log("selectedChat", selectedChat?.selectedChat?._id);
  const showDrawer = () => {
    setOpen(true);
    console.log("Device Width:", window.innerWidth);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <Button
        className="border border-blue-500 rounded-[10px] text-blue-500 h-[65px] w-[242px] "
        type="primary"
        onClick={showDrawer}
      >
        <i class="fa-solid fa-file-medical px-2"></i>
        Chat
      </Button> */}
      <button
        className="chat-control-button "
        width={40}
        height={40}
        // src={messageIcon}
        onClick={showDrawer}
        title="Message doctor"
      >
        <i class="fa-solid fa-message"></i>
      </button>
      <Drawer
        title="Chat"
        placement="right"
        width={window.innerWidth}
        size={window.innerWidth}
        onClose={onClose}
        open={open}
      >
        <div>
          {open && (
            <ChatBox
              selectedChat={{
                _id: selectedChat?.selectedChat?._id,
                booking: selectedChat?.selectedChat?.booking,
              }}
            />
          )}
        </div>
        <div className="border-t border-gray-400 my-2  "></div>
      </Drawer>
    </>
  );
};

export default ChatDrawer;
